<template>
<transition name="modal">
<div class="modal-mask">
    <div class="modal-wrapper">
        <div class="modal-container" @click.stop="">
            <div class="modal-title-bar">
                <h2 class="modal-header">{{$t('modal.shareRecTitle')}}</h2>
                <v-btn icon @click="close">
                    <div class="material-icons modal-title-close">clear</div>
                </v-btn>
            </div>
            <div class="modal-content">

                <transition name="slide-in" mode="out-in">
                <div v-if="!submitted">
                <h3>{{$t('modal.shareRecText')}}</h3>

                <div class="share-options" v-if="!submitted">

                    <div class="share-option">

                        <div class="share-select" @click="setPwd = !setPwd">
                            <div class="option-txt" >{{$t('modal.shareRecPwdCheck')}}</div>
                            <div div @click.stop="">
                            <v-switch color="success" v-model="setPwd"></v-switch>
                            </div>
                        </div>

                        <div class="share-expand" :class="setPwd ? 'show' : 'hide'">
                            <v-text-field
                            :label="$t('modal.shareRecPasscode')"
                            :type="showPwd ? 'text' : 'password'"
                            :append-icon="showPwd ? 'visibility' : 'visibility_off'"
                             @click:append="showPwd = !showPwd"
                            outline v-model="pwd"
                            ></v-text-field>

                            <div class="pwd-txt">{{pwdText}}</div>
                        </div>

                    </div>

                    <div class="share-option">
                        <div class="share-select" @click="setExp = !setExp">
                            <div class="option-txt" >{{$t('modal.shareRecSetExp')}}</div>
                            <div @click.stop="">
                            <v-switch color="success" v-model="setExp"></v-switch>
                            </div>
                        </div>

                        <div class="share-expand" :class="setExp ? 'show' : 'hide'">
                            <v-menu
                            v-model="dateMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            lazy
                            transition="scale-transition"
                            offset-y
                            full-width
                            min-width="290px"
                            >
                            <template v-slot:activator="{ on }">
                            <v-text-field
                            v-model="date"
                            outline
                            :label="$t('modal.shareRecExpiryDate')"
                            append-icon="event"
                            readonly
                            v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker v-model="date" @input="menu2 = false"></v-date-picker>
                            </v-menu>

                            <div>{{expText}}</div>

                        </div>
                    </div>

                    <div>{{$t('modal.shareRecDesc')}}</div>

                </div>

                </div>
                </transition>

                <transition name="slide-in" mode="out-in">
                <div v-if="submitted">

                    <h3>{{$t('modal.shareRecSuccess')}}</h3>

                    <div class="share-details">

                        <div class="share-link" >
                            <div class="material-icons">link</div>
                            <div @click="openLink" class="share-link-txt">{{shareLink}}</div>

                        <v-btn icon
                        class="copy-btn"
                        v-tooltip="{ content: $t('actions.copyToClipboard') , classes: 'app-tooltip'}"
                        @click="copyToClipboard" >
                        <v-icon class="material-icons">file_copy</v-icon>
                        </v-btn>
                        </div>

                    </div>

                </div>
                </transition>
        </div>

        <div class="button-row">
            <v-btn @click="close">
            {{$t('actions.cancel')}}
            </v-btn>

            <v-btn
            @click="shareRecording"
            class="focus-button"
            color="blue"
            v-if="!submitted"
            :disabled="!readyToSubmit"

        >{{$t('actions.confirm')}}</v-btn>

    </div>
    </div>
</div>
</div>
</transition>
</template>

<script>
import requests from './../../requests'
import EventBus from './../../eventBus'
import * as clipboard from 'clipboard-polyfill'
import analytics from './../../analytics'

export default {
    props: ["recDetails"],
    data () {
        return {
            setPwd: false,
            showPwd: false,
            pwd: "",
            pwdText: "",
            pwdErr: true,
            setExp: false,
            date: "",
            dateMenu: false,
            expText: "",
            expValid: true,
            expirationTime: null,
            shareID: "",
            submitted: false,
        }
    },
    computed : {
        validPwdInfo () {
            return !this.setPwd || (this.setPwd && !this.pwdErr)
        },
        validExpInfo () {
            return !this.setExp || (this.setExp && this.expValid)
        },
        shareLink () {
            return process.env.VUE_APP_SHARE_URL + this.shareID
        },
        readyToSubmit () {
            return this.validPwdInfo && this.validExpInfo
        }
    },
    methods: {
        shareRecording: function () {

            if (!this.validPwdInfo || !this.validExpInfo || this.pwd.length > 24){
                return
            }

            let payload = {
                Protected : this.setPwd,
                Expire: this.setExp,
            }

            if (this.setPwd) {
                payload.Password = this.pwd
            }

            if (this.setExp) {
                payload.ValidUntil = this.expirationTime
            }

            requests.shareRecording(this.recDetails.CollectionID, this.recDetails.ItemID, payload)
            .then( response => {

                if (response.data.data.ShareID) {
                    this.submitted = true
                    this.shareID = response.data.data.ShareID
                    analytics.gaShare('newShare')
                }

            })
            .catch(() => {
                EventBus.$emit("SHOW_MESSAGE", this.$t('modal.shareRecFailure'), "error")
            })

        },
        close: function () {
            if (this.submitted) {
                EventBus.$emit('UPDATE_CURR_REC')
            }

            this.$emit('close')

        },
        checkPassword: function () {

            if (this.pwd == "") {
                this.pwdText = ""
                this.pwdErr = true
                return
            }

            if (this.pwd.includes(" ")) {
                this.pwdText = this.$t('modal.shareRecErrSpaces')
                this.pwdErr = true
                return
            }

            if (this.pwd.length > 24) {
                this.pwdText = this.$t('modal.shareRecErrLengthMax')
                this.pwdErr = true
                return
            }

            if (this.pwd.length < 6) {
                this.pwdText = this.$t('modal.shareRecErrLengthMin')
                this.pwdErr = true
                return
            }

            this.pwdText = ""

            this.pwdErr = false



        },
        calculateExpiry: function (date) {

            let dateString = Date.parse(date)
            let newDate = new Date(dateString)
            newDate.setHours(23, 59, 59)

            return Date.parse(newDate)/1000

        },
        makeExpiry: function () {

            if (!this.setExp) {
                this.expValid = true
                this.expText = ""
                return
            }

            if (this.date == "" && this.setExp) {
                this.expText = this.$t('modal.shareRecExpErrDate')
                this.expValid = false
                return
            }

            const expiration = this.calculateExpiry(this.date)

            const timeNow = Math.round(Date.now()/1000)

            if (timeNow >= expiration) {
                this.expText = this.$t('modal.shareRecExpErrPast')
                this.expValid = false
                return
            }

            if (expiration > (timeNow + (365 * 24 * 60 * 60))) {
                this.expText = this.$t('modal.shareRecExpErrLength')
                this.expValid = false
                return
            }

            this.expValid = true
            this.expirationTime = expiration
            this.expText = this.$t('modal.shareRecExpAvailable') +  new Date(expiration * 1000)

        },
        openLink: function () {

            window.open(this.shareLink)

        },
        copyToClipboard: function () {

            clipboard.writeText(this.shareLink)
            .then(() => {
                EventBus.$emit('SHOW_MESSAGE', this.$t('actions.copySuccess'), 'success')
                })
            .catch(() => {
                EventBus.$emit('SHOW_MESSAGE', this.$t('actions.copyFailed'), 'error')
            })

        },
    },
    watch: {
        date: function() {
            this.makeExpiry()
        },
        setExp: function() {
            this.makeExpiry()
        },
        pwd: function () {
            this.checkPassword()
        }
    }
}
</script>

<style scoped>

.modal-content {
    overflow: hidden;
}

    h3 {
        margin-bottom: 1em;
    }

.share-option {
    border: 2px solid #4a4a4a;
    border-radius: 4px;
    margin-bottom: 1em;
}

.share-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 1em;
    cursor: pointer;
}

.no-touch .share-select:hover, .share-link:hover {
    color: #0BAAFF;
}

.share-link {
    display: flex;
    justify-content: center;
    border: 2px solid #4a4a4a;
    border-radius: 4px;
    padding: 0.6em 2em 0.6em 1em;
    align-items: center;
    position: relative;
    cursor: pointer;
    margin-bottom: 1em;
    width: 100%;
}

.share-link-txt {
    width: 100%;
    flex-flow: 3;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.option-txt {
    font-weight: 700;
    flex-grow: 2;
    text-align: left;
    padding: 1em 0;
}

.pwd-txt {
    height: 2em;
    font-weight: 700;
}

.share-expand {
    transition: all 0.6s;
    width: 80%;
    margin: 1em auto;
    overflow: hidden;
}

.hide {
    max-height: 0px;
    margin: 0 auto;
}

.show {
    max-height: 300px;
}

.share-details {
    display: flex;
    justify-content: center;
    margin: 2em 2em;
    align-items: center;
}

.share-details > * {
    margin: 0 0.5em;
}

.share-link {
    display: flex;
    justify-content: center;
    border: 2px solid #4a4a4a;
    border-radius: 4px;
    padding: 0.6em 2em 0.6em 1em;
    align-items: center;
    position: relative;
    cursor: pointer;
}


.copy-btn {
    position: absolute;
    right: -3.5em;
    top: 0;
    opacity: 0.4;
}

.share-link > * {
    margin: 0 10px;
    font-weight: 700;
}

</style>
