<template>
      <transition name="modal">
    <div class="modal-mask"
        @click="$emit('close')"
        >
      <div class="modal-wrapper">
        <div class="modal-container"
        @click.stop="">

    <div class="modal-title-bar">
            <h2 class="modal-header">{{$t('modal.renameRecordingTitle')}}</h2>
            <v-btn icon @click="$emit('close')">
            <div class="material-icons modal-title-close">clear</div>
        </v-btn>
    </div>

    <div class="modal-content">
        <v-text-field @keyup.enter="updateRecording"
        label="Name"
        v-model="recording.Name"
        counter="45"
        append-icon="video_library"
        outlined
        ></v-text-field>

        <!-- <v-menu
        v-model="dateMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        lazy
        transition="scale-transition"
        offset-y
        full-width
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="date"
            label="Enter recording date"
            append-icon="event"
            outline
            readonly
            v-on="on"
          ></v-text-field>

        </template>
        <v-date-picker v-model="date" @input="menu2 = false"></v-date-picker>
      </v-menu> -->

        <!-- <v-textarea
        label="Description"
        v-model="recording.Description"
        counter="250"
        outline
        ></v-textarea> -->

    <div class="button-row">
            <v-btn
            @click="$emit('close')"
            >
            {{$t('actions.close')}}
            </v-btn>

            <v-btn
            class="focus-button"
            color="blue"
            dark
            @click="updateRecording"
            >{{$t('actions.update')}}</v-btn>

    </div>
    </div>

          </div>
        </div>
      </div>
  </transition>
</template>

<script>
export default {

data () {
    return {
      recording: {},
      // date: null,
      dateMenu: false,
    }
},
methods : {
  updateRecording: function () {

    if (this.recording.Name.length > 45) {
      return
    }

    this.recording.Name = this.recording.Name.trim()

    // if (this.recording.Description) {

    //   if (this.recording.Description.length > 250) {
    //     return
    //   }

    //   this.recording.Description = this.recording.Description.trim()
    // }

    // let d = Date.parse(this.date)
    // let newDate = new Date(d).toISOString()

    const recordingPayload = {
      Name : this.recording.Name,
      // Description : this.recording.Description,
      // Date: newDate,
      UserID: this.$store.getters.USER_ID
    }

    const payload = {
      "collID": this.recording.CollectionID,
      "itemID": this.recording.ItemID,
      "recording": recordingPayload,
    }
    this.$store.dispatch('UPDATE_CURR_RECORDING', payload)

    this.$emit('close')
    }
  },
  created () {

    // create a copy of the recDetails object so that we can edit without it binding to our actual data
      this.recording = Object.assign({}, this.$store.getters.REC_DETAILS)
      // this.date = this.recording.Date.substring(0,10)
    },
  }
</script>

<style scoped>

.collection-form {
    margin: 20px 0 0 0;
}

.button-row {
    padding: 20px 0 0 0;
    display: flex;
    justify-content: flex-end
}

</style>
