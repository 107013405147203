<template>

<transition appear name="slide-fade">
<div id="notepad" class="card-shadow" v-if="recDetails">

    <div class="notepad--save">
            <div class="save-msg" v-tooltip="{ content: saveMsg.text , classes: 'app-tooltip'}" v-bind:style="{color: saveMsg.color}">
                <div class="material-icons">{{saveMsg.icon}}</div>
            </div>
    </div>

    <div class="notepad--content">
    <quillEditor
    ref="notepad"
    :options="editorOption"
    @change="onEditorChange($event)"
    >
    </quillEditor>

    </div>
    </div>
    </transition>

</template>

<script>
import 'quill/dist/quill.core.css'
import {mapGetters} from 'vuex'
import { quillEditor } from 'vue-quill-editor'
import requests from './../../requests'
import analytics from './../../analytics'

export default {
    data () {
        return {
            noteObject: {},
            savedObject: {},
            changeMade: false,
            autoSaveTimer: null,
            noteText: "",
            error: false,
            loading: true,
            editorOption: {
                placeholder: "Your notes...",
                modules: {
                    toolbar: [
                        ['bold'],
                        [{ 'header': [1, 2, 3, false] }],
                        [{ 'color': [] }],
                        [{ 'list': 'ordered'}],
                        [ { 'list': 'bullet' }],
                        ['link'],
                        ['clean']
                    ],
                }
            },
        }
    },
    methods: {
        onEditorChange({quill, text}) {
            this.noteObject = quill.editor.delta
            this.noteText = text

            if (!this.loading) {
                this.changeMade = true
            }

        },
        setNoteObject: function () {
            this.$refs.notepad.quill.setContents(this.recDetails.Notes.ops)
        },
        saveNotes: async function () {
            this.postNewNotes()
            this.changeMade = false
            this.savedObject = this.noteObject
        },
        postNewNotes: async function () {

            const recordingPayload = {
                "UserID": this.$store.getters.USER_ID,
                "Notes": this.noteObject
            }

            const response = await requests.updateRecording(this.recDetails.CollectionID, this.recDetails.ItemID, recordingPayload)
            if (response.status != 200) {
                this.error = true
            }

            analytics.gaRecording('noteUpdate', this.recDetails)

        }
    },
    components: {
        quillEditor,
  },
    computed : {
        ...mapGetters({
            currentTime : 'GET_CURRENT_TIME',
            recDetails: 'REC_DETAILS',
        }),
        saveMsg () {

            if (this.error) {
                return {icon: "error", text: "Error saving", color: "#FF5624"}
            } else if (!this.changeMade) {
                return  {icon: "check", text: "saved", color: "#00BB57"}
            } else {
                return {icon: "cached", text: "saving...", color: "gray"}
            }
        }
    },

  mounted() {
      if (this.recDetails && this.recDetails.Notes) {
          this.setNoteObject()
      }

      let self = this
      setTimeout(function()  {
        self.loading = false
      }, 2000 )

      this.autoSaveTimer = setInterval(() => {

          if (this.changeMade && !this.loading) {
              this.saveNotes()
          }
      }, 3000)

  },
  beforeDestroy () {
      clearInterval(this.autoSaveTimer)
      this.autoSaveTimer = null
  }

}
</script>

<style>

#notepad{
    padding: 2.5em 2em 2em 2em ;
    position: relative;
    display: block;
    border-radius: 6px;
}

.save-msg {
    position: absolute;
    top: 0.8em;
    right: 1.5em;
    display: flex;
    align-items: center;
    font-size: 12px;
    transition: all 0.4s ;
}

.save-msg .material-icons {
    font-size: 18px;
}

.notepad--content{
    max-height: 50em;
    /* overflow: hidden; */
    transition: all 0.5s ease-out;
}

.notepad--content-hidden {
    max-height: 0;
    opacity: 0;
}

.ql-toolbar{
    text-align: left;
}


#notepad .ql-editor{
    min-height: 5em;
    height: 550px;
    display: block;
    font-size: 1.2em;
}


/* #notepad .ql-editor {
    height: 420px;
    /* background-color: #00000010;
}
*/

#notepad .ql-toolbar.ql-snow {
    display: flex;
    padding: 0.5em 2em;
}



</style>

<style>

.note-card .ql-container.ql-snow, #edit-notepad .ql-container.ql-snow {
    border: 0px;
    color:rgb(106, 106, 106);
    font-size: 14px;
}

.note-card .ql-toolbar {
    position: absolute;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    width: 0;
    height:0;
    pointer-events: none;
}
</style>