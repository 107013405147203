<template>

<v-card>

  <v-form
  class="add-card"
    ref="form"
    lazy-validation
  >
    <div class="top-row">
        <div class="hl-title">
            <v-text-field
            v-model="highlight.Name"
            :label="$t('highlights.titleLabel')"
            :placeholder="$t('highlights.titlePlaceholder')"
            required
            clearable
            autofocus
            solo
            @keyup.enter="enterHighlight"
            prepend-inner-icon="bookmark"
            ></v-text-field>
        </div>

        <div class="timebox">

        <div>
            <div class='startfinish'>

            <div class="timestamp">
                <h6>{{$t('highlights.labelStart')}}</h6>
            </div>
                <div class="time-controls">

                    <div class="saylient-btn material-icons time-btn" v-ripples v-tooltip="{ content: '-1 second' , classes: 'app-tooltip'}" @click="incrementRange('min', -1)">keyboard_arrow_left</div>
                    <div class="saylient-btn material-icons time-btn" v-ripples v-tooltip="{ content: 'Jump to current time' , classes: 'app-tooltip'}" v-on:click="setSliderRange('min')">fiber_manual_record</div>
                    <div class="saylient-btn material-icons time-btn" v-ripples v-tooltip="{ content: '+1 second' , classes: 'app-tooltip'}" @click="incrementRange('min', 1)">keyboard_arrow_right</div>
                </div>

                {{sliderMinTime}}
            </div>
        </div>

        <div>
            <div class="startfinish">

                <div class="timestamp">
                <h6>{{$t('highlights.labelFinish')}}</h6>

                </div>

                <div class="time-controls">

                    <div class="saylient-btn material-icons time-btn" v-ripples v-tooltip="{ content: '-1 second' , classes: 'app-tooltip'}" @click="incrementRange('max', -1)">keyboard_arrow_left</div>
                    <div class="saylient-btn material-icons time-btn" v-ripples v-tooltip="{ content: 'Jump to current time' , classes: 'app-tooltip'}" v-on:click="setSliderRange('max')">fiber_manual_record</div>
                    <div class="saylient-btn material-icons time-btn" v-ripples v-tooltip="{ content: '+1 second' , classes: 'app-tooltip'}" @click="incrementRange('max', 1)">keyboard_arrow_right</div>

                </div>
                {{sliderMaxTime}}
            </div>
        </div>

        </div>
   </div>

    <div class="hl-tags">
        <v-combobox
        v-model="highlight.Tags"
        :items="recTags"
        :label="$t('highlights.tagsLabel')"
        :placeholder="$t('highlights.tagsPlaceholder')"
        chips
        clearable
        multiple
        solo
        prepend-inner-icon="local_offer"

    ></v-combobox>
        </div>

    <div class="hl-description">
        <v-textarea
        v-model="highlight.Description"
        :label="$t('highlights.descLabel')"
        :placeholder="$t('highlights.descPlaceholder')"
        prepend-inner-icon="subject"
        solo

        ></v-textarea>
    </div>

    <div class="button-row">

            <v-btn
      @click="closeAddHighlight"
    >
    {{$t('actions.cancel')}}
    </v-btn>

    <v-btn
      v-if="!editing"
      color="#0BAAFF"
      dark
      @click="addNewHighlight"
    >
    {{$t('actions.ok')}}
    </v-btn>

        <v-btn
      v-if="editing"
      color="#0BAAFF"
      dark
      @click="editHighlight"
    >
    {{$t('actions.update')}}
    </v-btn>

    </div>



</v-form>

</v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data () {
        return {
            highlight: {
                Name: '',
                Description: '',
                TimeStart: 0,
                TimeEnd: 0,
                Tags: []
            }
        }
    },
    computed: {
        ...mapActions({
        setSliders : 'SET_SLIDER_RANGE'
            }),
        ...mapGetters({
        sliderMin : 'GET_MIN',
        sliderMax : 'GET_MAX',
        currTime: 'GET_CURRENT_TIME',
        editing: 'GET_EDITING',
        editingHighlight: 'GET_EDIT_HIGHLIGHT',
        recTags : 'TAGS',
        recLoaded: 'REC_LOADED',
            }),
        sliderMinTime: function () {
            return this.$options.filters.timeFormat(this.sliderMin)
            },
        sliderMaxTime: function () {
            return this.$options.filters.timeFormat(this.sliderMax)
            },
        notReadyToSubmit: function () {
            return this.highlight.Name == '' ? true : false
        }
    },
    methods : {
        addNewHighlight: function () {

            if (this.notReadyToSubmit) {
                return
            }

            this.highlight.Name = this.highlight.Name.trim()

            if (this.highlight.Description) {
                this.highlight.Description = this.highlight.Description.trim()
            }

            this.highlight.TimeStart = parseInt(this.sliderMin)
            this.highlight.TimeEnd = parseInt(this.sliderMax)

            this.$store.dispatch('NEW_HIGHLIGHT', {
                "params": this.$route.params ,
                "highlight": this.highlight})
            this.$store.dispatch('CLOSE_HL_MODAL')
        },
        editHighlight: function () {

            if (this.notReadyToSubmit) {
                return
            }

            this.highlight.Name = this.highlight.Name.trim()

            if (this.highlight.Description) {
                this.highlight.Description = this.highlight.Description.trim()
            }

            this.highlight.TimeStart = parseInt(this.sliderMin)
            this.highlight.TimeEnd = parseInt(this.sliderMax)
            this.$store.dispatch('UPDATE_HIGHLIGHT', {
                "params": this.$route.params,
                "highID" : this.highlight.HighlightID,
                "highlight": this.highlight,
                })

            this.$store.dispatch('CLOSE_HL_MODAL')

        },
        enterHighlight: function () {

            if (!this.editing) {
                this.addNewHighlight()
            } else {
                this.editHighlight()
            }

        },
        closeAddHighlight: function () {
            this.$store.dispatch('CLOSE_HL_MODAL')
        },
        setSliderRange: function(point) {
            if (point === 'min') {
                    this.$store.dispatch('SET_SLIDER_RANGE',[this.currTime, this.sliderMax])
            } else if (point === 'max') {
                    this.$store.dispatch('SET_SLIDER_RANGE',[this.sliderMin, this.currTime])
            } else {
                return
            }
        },
        incrementRange: function(point, increment) {

            if (point === 'min') {
                    this.$store.dispatch('SET_SLIDER_RANGE',[(this.sliderMin + increment), this.sliderMax])
            } else if (point === 'max') {
                    this.$store.dispatch('SET_SLIDER_RANGE',[this.sliderMin, (this.sliderMax + increment)])
            } else {
                return
            }
        }
    },
    mounted () {

        if (this.editing) {
            this.highlight = this.editingHighlight
            this.$store.dispatch('SET_SLIDER_RANGE',
             [this.editingHighlight.TimeStart, this.editingHighlight.TimeEnd])
        }

    },
    beforeDestroy () {
        this.closeAddHighlight()
        }
}
</script>

<style scoped>

.hl-title {
    flex-grow: 2;
}

.timebox {
    display: flex;
}

.timebox > div {
    box-sizing: border-box
}

.add-card {
    padding: 1.5rem;
}

.top-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.top-row > * {
    display: flex;
    flex-direction: row;
    margin: 1.5rem 1.5rem 0 1.5rem;
}

.spacer {
    width: 1rem;
}

.hl-description {
    margin: 0 1.5rem;
}

.hl-tags {
    margin: 0 1.5rem;
}

i {
    color: #0BAAFF;
}

.startfinish {
    width: 100px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-content: center;
    align-items: center;
}

.timestamp h6 {
    color: grey;
}

/* .startfinish div {
    margin: 0 -5px;
} */

.arrow {
    margin: -10px 0;
}

.icon-box {
    margin: 0px;
    width: 10px;
}


.time-controls {
    display: flex;
    align-items: center;
    justify-content: center;
}


@media (min-width: 1px) and (max-width: 800px) {

    .timebox {
        justify-content: space-between;
        padding: 0 1.5em;
        /* border: 1px solid blue; */
    }

    .time-controls {
        order: -1;
        margin: 0 1em;
        /* border: 1px solid pink; */
    }

    .time-btn {
        font-size: 3em;
    }

    .top-row {
        flex-flow: column-reverse;
    }
}

#app .hl-edit-btn {
    margin: 0 2px;
}

.time-btn {
    border-radius: 100%;
    margin: 0;
    color: #0BAAFF;
}

</style>


