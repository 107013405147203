<template>
      <transition name="modal">
    <div class="modal-mask"
        @click="$emit('close')"
        >
      <div class="modal-wrapper">
        <div class="modal-container"
        @click.stop="">

      <div class="modal-title-bar">
            <h2 class="modal-header">{{$t('modal.renameSpeakersTitle')}}</h2>
            <v-btn icon @click="$emit('close')">
            <div class="material-icons modal-title-close">clear</div>
        </v-btn>
    </div>

    <div class="modal-content"></div>
    <v-form v-on:submit.prevent="onSubmit">

    <div
    class="speaker-row"
    v-for="(name, index) in names" :key="index">

      <h4 class="speaker-name">{{name.Speaker | speakerize}} </h4>

      <div class="alias-name">
        <v-text-field
        v-model="name.Alias"
        placeholder="Name"
        single-line
        clearable
        outlined
        ></v-text-field>
      </div>

    </div>

    <div class="button-row modal-controls">
            <v-btn
            @click="$emit('close')"
            >
            {{$t('actions.close')}}
            </v-btn>

            <v-btn
            class="focus-button"
            color="blue"
            light
            @click="updateRecording()"
            >{{$t('actions.update')}}</v-btn>
    </div>

    </v-form>

          </div>
        </div>
      </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
import EventBus from './../../eventBus'

export default {
    data () {
        return {
          names: [],
        }
    },
    methods : {
      updateRecording: function () {

            this.names.forEach( name => {
              if (name.Alias) {
                name.Alias = name.Alias.trim()
              }
            })

            const updates = {
              "SpeakerNames" : this.names
            }

            const payload = {
              "collID": this.recording.CollectionID,
              "itemID": this.recording.ItemID,
              "recording": updates,
            }

            this.$store.dispatch('UPDATE_CURR_RECORDING', payload)
            EventBus.$emit("REFRESH_TRANSCRIPT")
            EventBus.$emit("SHOW_MESSAGE", this.$t('modal.renameSpeakersSuccess'), "success")
            this.$emit('close')

      },
    },
    watch: {

    },
    computed: {
        ...mapGetters({
            speakers : 'SPEAKERS',
            speakerNames: 'SPEAKER_NAMES',
            recording: 'REC_DETAILS'
            }),
    },
    mounted () {

      if (this.speakerNames) {

        this.speakerNames.forEach( speakerName => {
          this.names.push({
            "Speaker" : speakerName.Speaker,
            "Alias": speakerName.Alias
            })
        })

      } else {

        this.speakers.forEach( speaker => {
          this.names.push({
            "Speaker" : speaker,
            "Alias": ""
            })
      })

      }



      }
}
</script>

<style scoped>

.speaker-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.speaker-row h4 {
  text-align: right
}

.speaker-name {
  margin: 30px;
}


</style>
