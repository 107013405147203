<template>
      <transition name="modal">
    <div class="modal-mask"
        @click="$emit('close')"
        >
      <div class="modal-wrapper">
        <div class="modal-container"
        @click.stop="">



      <div class="modal-title-bar">
          <h2 class="modal-header">{{$t('modal.deleteHLConfirm')}}</h2>
          <v-btn icon @click="$emit('close')">
          <div class="material-icons modal-title-close">clear</div>
      </v-btn>
      </div>

      <div class="modal-content">
        <h3 class="modal-sub-header">
        {{$t('modal.deleteHLMsg')}} {{hlForDeleting.Name}}?
        </h3>
      </div>

    <div class="button-row">
            <v-btn
            @click="$emit('close')"
            >
            {{$t('actions.close')}}
            </v-btn>

            <v-btn
            class="focus-button"
            color="#0BAAFF"
            light
            @click="confirmDelete"
            >{{$t('actions.delete')}}</v-btn>

    </div>

          </div>
        </div>
      </div>
  </transition>
</template>

<script>

export default {
  props: ['hlForDeleting'],
  methods : {
    confirmDelete: function () {
      this.$emit('deletehighlight', this.hlForDeleting)
      this.$emit('close')
    }
  },
  computed: {
    recordingShow () {

        if (this.delcollection.Summary.Recordings) {
          return true
        } else {
            return false
        }
      }
  }
}
</script>

<style scoped>

.rec-names {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 40%;
  margin: 0 auto;
  padding: 12px 0;
}

.modal-sub-header {
  margin-top: 20px;
  color: rgba(0, 0, 0, .75)
}

.rec-summary-name {
  display: flex;
  align-items: center;
  margin: 8px 12px 8px 0px;
  color: rgba(0, 0, 0, .33)
}

.rec-name {
  font-size: 18px;
  color: rgba(0, 0, 0, .75)
}


</style>
