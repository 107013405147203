<template>

<transition appear name="slide-fade">
    <div>
        <app-rename-speakers
        v-if="isRenaming"
        @close="isRenaming = false"
        ></app-rename-speakers>

        <app-export-transcript
        v-if="isExporting"
        @close="isExporting = false"
        :transcript="transcript"
        :speakerNames="speakerNames"
        ></app-export-transcript>

        <confirm-md
        v-if="isRestoring"
        @close="isRestoring = false"
        @confirm="restoreTranscript"
        :msg="$t('transcript.restoreText')"
        :title="$t('transcript.restoreTitle')"
        ></confirm-md>

        <download-captions v-if="isDownloading" @close="isDownloading = false" :recording="recDetails"></download-captions>

        <div id="no-transcript" v-if="status == 'none'">
            <v-btn
            color="#0BAAFF"
            @click="startTranscribing"
            dark
            >{{$t('transcript.transcribeRecording')}}</v-btn>
            <h4>{{$t('transcript.noTranMsg')}}</h4>
        </div>

    <div v-if="status != 'none'" class="relative">
        <div class="loading-overlay tran-overlay" v-if="(!readyTranscript && status != 'processing' && !emptyTranscript)">
            <i class="material-icons spin-delay">sms</i>
            <p>{{$t('transcript.loadingTranscript')}}</p>
        </div>

        <div class="transcript-window" v-if="readyTranscript">

            <div class="transcript-options" v-if="false">

                <div class="search-box">
                    <input type="text" placeholder="Search conversation" v-model="textSearch"
                    @input="createQuotes()"
                    @keyup.enter="createQuotes()"
                    >
                    <div class="material-icons saylient-btn" @click="textSearch = ''" v-ripples>clear</div>
                </div>

                <v-btn icon @click="isExporting = true" v-tooltip="{ content: 'Text Transcript' , classes: 'app-tooltip'}">
                    <div class="material-icons">list</div>
                </v-btn>

                <v-btn icon @click="isDownloading = true" v-tooltip="{ content: 'Caption Files' , classes: 'app-tooltip'}">
                    <div class="material-icons">subtitles</div>
                </v-btn>

                <v-menu offset-y left v-tooltip="{ content: 'Transcript Options' , classes: 'app-tooltip'}">
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                        <v-icon>settings</v-icon>
                    </v-btn>
                </template>

                <v-list>
                <v-list-item @click="isRenaming= true">
                        <v-list-item-title>{{$t('transcript.renameSpeakers')}}</v-list-item-title>
                </v-list-item>

                <v-list-item @click="isRestoring = true">
                        <v-list-item-title>{{$t('transcript.restoreOriginal')}}</v-list-item-title>
                </v-list-item>

                <!-- <v-list-item @click="isDownloading = true">
                        <v-list-item-title>{{$t('transcript.downloadCaptions')}}</v-list-item-title>
                </v-list-item> -->

                </v-list>

                </v-menu>

            </div>

            <div class="transcript-menu card">

                <v-btn small icon outlined :color="autoScroll ? 'blue' : ''" @click="autoScroll = !autoScroll"><v-icon class="material-icons"
                v-tooltip="{ content: $t('transcript.autoScroll') , classes: 'app-tooltip'}"

                >{{autoScroll ? 'keyboard_arrow_down' : 'remove'}}</v-icon></v-btn>
                <v-btn small icon outlined :color="showFlags ? 'blue' : ''"  @click="showFlags = !showFlags"
                v-tooltip="{ content: $t('transcript.onlyShowFlags') , classes: 'app-tooltip'}"
                ><v-icon class="material-icons">{{showFlags ? 'bookmark' : 'bookmark_outline'}}</v-icon></v-btn>

                <v-text-field
                v-model="textSearch"
                @input="createQuotes()"
                @click:clear="textSearch = ''"
                label="Search Transcript"
                outlined
                prepend-inner-icon="search"
                hide-details
                dense
            ></v-text-field>

            <!-- <v-btn outlined rounded tile ><v-icon  class="material-icons">list</v-icon></v-btn>
            <v-btn outlined rounded tile ><v-icon  class="material-icons">subtitles</v-icon></v-btn> -->

            <v-menu offset-y left v-tooltip="{ content: 'Transcript Options' , classes: 'app-tooltip'}">
            <template v-slot:activator="{ on }">
            <v-btn height="40" v-on="on" outlined><v-icon class="material-icons">save_alt</v-icon><v-icon small class="material-icons">keyboard_arrow_down</v-icon></v-btn>
            </template>

            <v-list>
                <v-list-item @click="isExporting = true">
                        <v-list-item-title>{{$t('transcript.exportText')}}</v-list-item-title>
                </v-list-item>

                <v-list-item @click="isDownloading = true">
                        <v-list-item-title>{{$t('transcript.exportCaptions')}}</v-list-item-title>
                </v-list-item>

                </v-list>
            </v-menu>

            <v-menu offset-y left v-tooltip="{ content: 'Transcript Options' , classes: 'app-tooltip'}">
            <template v-slot:activator="{ on }">
            <v-btn height="40" v-on="on" outlined><v-icon class="material-icons">settings</v-icon><v-icon small class="material-icons">keyboard_arrow_down</v-icon></v-btn>
            </template>

            <v-list>
                <v-list-item @click="isRenaming= true">
                        <v-list-item-title>{{$t('transcript.renameSpeakers')}}</v-list-item-title>
                </v-list-item>

                <v-list-item @click="isRestoring = true">
                        <v-list-item-title>{{$t('transcript.restoreOriginal')}}</v-list-item-title>
                </v-list-item>

                </v-list>
            </v-menu>


            </div>

            <div id="transcript-block" v-if="showTranscript">

                <div class="transcript-cards">
                    <transcript-card v-for="(quote, index) in filteredQuotes"
                        :key="index"
                        :quote="quote"
                        :index="index"
                        :textSearch="textSearch"
                        :showFlags="showFlags"
                        :hide="textSearch == '' ? false : quote.hide"
                    ></transcript-card>

                </div>
            </div>

        </div>

            <div v-if="status == 'processing'" class="nocontent-warning">
            <transition appear name="slow-fade">
            <div class="description">

            <div>
                <i class="material-icons icon big-icon spin">autorenew</i>
                <h3>{{$t('transcript.creatingTranscript')}}</h3>
            </div>

            <h4>{{  $t( 'transcript.transcriptEmailMsg', {email : userDetails.Email}) }}</h4>
            </div>
            </transition>

            </div>

            <div v-if="(!validTranscript || emptyTranscript) && loaded" class="nocontent-warning">

            <transition appear name="slow-fade">
            <div class="description">

            <div>
                <i class="material-icons icon big-icon">error</i>
                <h3>{{$t('transcript.transcriptErr')}}</h3>
            </div>

            <h4>{{$t('transcript.transcriptErrText')}}</h4>

            </div>
            </transition>
            </div>

        </div>
    </div>
</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import RenameSpeakers from './RenameSpeakersMD'
import ExportTranscript from './ExportTranscript'
import TranscriptCard from './TranscriptCard'
import DownloadCaptions from './DownloadCaptionsMD'
import ConfirmMd from './../UI/ConfirmMD'
import EventBus from './../../eventBus'
import requests from './../../requests'
import analytics from './../../analytics'

export default {
    data () {
        return {
            transcriptCardComponent: TranscriptCard,
            textSearch: '',
            regenerating: false,
            polling: null,
            options: [
                { title: this.$t('transcript.renameSpeakers') },
            ],
            isRenaming: false,
            isExporting: false,
            isRestoring: false,
            isDownloading: false,
            filteredQuotes: [],
            showTranscript: true,
            scrolling: false,
            loaded: false,
            eventBuffer: 0,
            autoScroll: true,
            showFlags: false,
            currentPos: 0,
            futurePos: 0,
            testFlags: []
        }
    },
    components : {
        appRenameSpeakers : RenameSpeakers,
        appExportTranscript: ExportTranscript,
        TranscriptCard,
        ConfirmMd,
        DownloadCaptions
    },
    computed : {
    ...mapGetters({
        transcript : 'GET_TRANSCRIPT',
        currentTime: 'GET_CURRENT_TIME',
        readyTranscript: 'READY_TRANSCRIPT',
        status: 'TRAN_STATUS',
        cognitoInfo: 'COGNITO_INFO',
        speakerNames: 'SPEAKER_NAMES',
        recDetails: 'REC_DETAILS',
        userDetails: 'USER_DETAILS'
        }),
        noTranscript () {
            return this.status == 'none' ? false : true
        },
        validTranscript () {
            return this.status == 'error' ? false : true
        },
        emptyTranscript () {
            return this.recDetails.TranscribeStatus == 'COMPLETED' && this.transcript.length == 0 ? true : false
        },
        accessDenied() {
          return this.$store.getters.ACCESS_DENIED
        },
        searchTermList () {
            return this.textSearch.length ?
            this.textSearch.toLowerCase().trim().split(" ")
            : []
        },
        searchTerms () {
            return this.searchTermList.filter(term => term.length > 1 )
        },
        scrolledBelow() {
            return this.currentPos > this.futurePos ? true : false
        }
    },
    watch :{
        'status' : 'getTranscript'
    },
    methods : {
        refreshTranscript: function () {
            this.makeQuotes()
        },
        handleScroll: function () {

      if (!this.scrolling) {
        clearTimeout(this.scrollTimer)

            this.scrolling = true

            this.scrollTimer = setTimeout( () => {
                this.scrolling = false
            }, 100)

            }
        },
        fireSearchEvent: function () {

            if (this.eventBuffer > 0) {
                return
            }

            this.eventBuffer = 1

            let self = this

            setTimeout(() => self.eventBuffer = 0, 8000)

            analytics.gaTranscript('search')

        },
        createQuotes: function () {

            this.regenerating = true

            if (this.searchTerms.length == 0) {
                this.removeHidden()
                // this.filteredQuotes.forEach( quote => quote.hide =  false)
                this.regenerating = false
                return
            }

            const search = this.textSearch.toLowerCase().trim().split(" ")
            if (!search) {
                this.removeHidden()
                this.regenerating = false
                return
            }

            this.filterQuotes()
            this.regenerating = false
            this.fireSearchEvent()

        },
        removeHidden: function () {

            for (var i = 0, len = this.filteredQuotes.length; i < len; i++) {
                    this.filteredQuotes[i].hide = false
                }

        },
        filterQuotes: function () {

            for (var i = 0, len = this.filteredQuotes.length; i < len; i++) {

                const newQuote = this.filterDialogue(this.filteredQuotes[i])
                this.filteredQuotes.splice(i, 1, newQuote)
            }
        },
        makeQuotes: function () {

            this.filteredQuotes = []

            for (var i = 0, len = this.transcript.length; i < len; i++) {
                this.filteredQuotes.push(this.transcript[i])
            }

        },
        filterDialogue: function (quote) {

            var self = this

            // map over the dialogue object in each quote block and check to see if each word contains any of our search terms
            let words = quote.Dialogue.map(function(e) {
                    return self.searchTerms.some(term => e.W.toLowerCase().includes(term))
                })
            // returns true if any of our search term exists in any of the words in the dialogue
            if (!words.some(function(e){return e})) {
                quote.hide = true
            } else {
                quote.hide = false
            }

            return quote

        },
        getTranscript () {

            clearInterval(this.polling)
            let self = this

            if (this.status == 'none') {
                return
            }

            if (this.recDetails.TranscribeStatus == "COMPLETED") {
                this.fetchTranscript()
                return
            }

            // poll every 15 seconds to see if transcribing is complete and available
            // cancel the interval if all recordings are finished
            self.polling = setInterval(() => {

            const payload = {
                collID: this.$route.params.collID,
                recID: this.$route.params.recID,
            }

            this.$store.dispatch('SET_CURRENT_RECORDING_TSTATUS', payload)

                if (this.status == 'ready') {

                    this.fetchTranscript()

                    clearInterval(self.polling)

                }}, 4000)

        },
        restoreTranscript: function () {
            requests.restoreTranscript(this.$route.params.collID, this.$route.params.recID)
            .then(response => {

                if (response.status == 200) {

                    this.$store.dispatch("UPDATE_TRANSCRIPT", response.data.data.Transcript)
                    this.refreshTranscript()
                    this.isRestoring = false
                }

            })
        },
        fetchTranscript: function () {

            const payload = {
                    userID: this.cognitoInfo.sub,
                    collID: this.$route.params.collID,
                    recID: this.$route.params.recID,
                }

                this.$store.dispatch('SET_TRANSCRIPT', payload).then(()=> {
                    this.makeQuotes()
                })
        },
        startTranscribing: function () {
            EventBus.$emit("START_TRANSCRIBE")
        }
    },
    filters: {
    },
    beforeDestroy () {
        clearInterval(this.polling)
    },
    mounted () {

        this.testFlags = [
            {Time: 11, label : ""},
            {Time: 23, label : ""},
            {Time: 67., label : ""},
            {Time: 72, label : ""},
            {Time: 120, label : ""},
        ]

        this.getTranscript()

        setTimeout(() => {
            this.loaded = true
        }, 4000)

        EventBus.$on("REFRESH_TRANSCRIPT", () => {
            this.refreshTranscript()
        })

        EventBus.$on("SCROLL_TRANSCRIPT", (id) => {

            if (!this.autoScroll || this.scrolling) {
                return
            }

            this.currentPos = document.getElementById('transcript-block').scrollTop
            this.futurePos = document.getElementById(id).offsetTop

            if (this.scrolledBelow) {
                return
            }

            document.getElementById('transcript-block').scrollTo({ top: this.futurePos, behavior: 'smooth' })

        })

    }
}

</script>

<style lang="scss">

  #transcript-block {
    margin-top: 0.5em;
    flex-grow: 2;
    padding: 5px;
    position: relative;
    overflow-y: auto;
    border-bottom: 1px solid #00000010;
    padding-top: 32px;
    display: flex;
    flex-flow: column;
  }

  .loading-bar {
      width: 1000%;
      height: 10px;
      background: red;
  }

 @media (min-width: 1px) and (max-width: 800px) {

  #transcript-block {
      margin-top: 0.5em;
  }

}

</style>

<style scoped lang="scss">
@import '@/styles/abstracts/_variables';


#transcript-block {
    margin-top: 10px;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
}

.transcript-window {
    height: 75vh;
    display: flex;
    flex-flow: column nowrap;
}

.transcript-options {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 0.7em;
}

.transcript-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
}

.transcript-menu * {
    margin: 0 0.2em;
}

.transcript-options button {
    color: rgba(0,0,0,.54);
}

.nocontent-warning {
    margin: 40px auto;
    color: $title-font;
    font-size: 18px;
}
.tran-overlay {
    margin-top: 7em;
}

#no-transcript {
    margin: 48px auto;
    width: 80%;
    display: flex;
    flex-flow: column;
    align-items: center;
}

#no-transcript h4 {
    margin: 48px 0 24px 0;
}

#no-transcript h4 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 32px;
  color:rgba(0,0,0,.77)
}

#no-transcript button {
    border-radius: 4px;
}

#transcript-block {
    scrollbar-width: none;
}

#transcript-block::-webkit-scrollbar {
  display: none;
}

@media (min-width: 1px) and (max-width: 1000px) {
    .transcript-window {
        height: auto;
    }
}

#app .transcript-menu:hover {
    border: 1px solid #4a4a4a40;

}

</style>
