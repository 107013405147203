<template>
<div class="options-md modal-mask no-highlight" @click="$emit('close')"  @click.stop="">

    <transition name="fade" appear>
    <div class="modal-container">

        <div class="modal-content">

         <div class="modal-options" @click.stop="">
            <div v-for="option in options" :key="option.event" class="option saylient-btn" @click="emitEvent(option.event)">
                {{option.name}}
            </div>
        </div>

        </div>

        <div>
        </div>
    </div>
    </transition>
</div>

</template>

<script>
export default {
    props: ["tOption", "type"],
    data () {
        return {
            options: [],
            hlOptions: [
                {name: this.$t('actions.copyText'), event : "copyhl"},
                {name: this.$t('actions.play'), event : "playhl"},
                {name: this.$t('actions.edit'), event : "edithl"},
                {name: this.$t('actions.delete'), event : "deletehl"},

            ],
            tOptions: [
                {name: this.$t('actions.play'), event : "playtran"},
                {name: this.$t('actions.copyText'), event : "copytran"},
                {name: this.$t('actions.edit'), event : "showedittran"},
                {name: this.$t('highlight.makeHighlight'), event : "makehighlight"},
            ]
        }
    },
    mounted () {
        if (this.type == "transcript") {
            this.options = this.tOptions
        } else {
            this.options = this.hlOptions
        }
    },
    methods: {
        emitEvent: function (event) {

            this.$emit(event)

            setTimeout( () => {
                this.$emit('close')
            }, 500)

        }
    }
}
</script>

<style scoped>

.modal-mask {
    z-index: 999;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #ffffff99;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}


.modal-container {
    z-index: 11;
    width: 90%;
    max-width: 500px;
    background-color: white;
    margin: 0 2em;
    display: flex;
    flex-flow: column;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 3px 3px 10px #00000050, 0px 0px 100px #00000050;
    padding: 0;
    height: auto;
}

.modal-content {
    width: 90%;
    padding: 2em 1em;
    height: 100%;
    overflow: hidden;
}

.modal-options {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
    height: 100%;
    width: 100%;
}

.option {
    font-weight: 700;
    margin: 0 auto;
    font-size: 1.2em;
    padding: 0.5em 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.option:not(:first-child) {
    border-top: 1px solid #4a4a4a40;

}

.option:hover {
    color: #0BAAFF;
}

.modal-content {
    padding: 0.5em 1em;
}

</style>

