<template>
    <div>

        <app-edit-recording v-if="showEditRecording"
            @close="showEditRecording = false"></app-edit-recording>

        <app-delete-recording
        v-if="showDeleteRecording"
        @close="showDeleteRecording = false"
        :recDetails="recDetails"
        >
        </app-delete-recording>

        <app-share-recording
        v-if="showShareRec"
        @close="showShareRec = false"
        :recDetails="recDetails"
        >
        </app-share-recording>

        <app-edit-share-recording
        v-if="showEditShareRec"
        @close="showEditShareRec = false"
        :recDetails="recDetails"
        >
        </app-edit-share-recording>

            <div class="interview-actions">

                <v-btn
                v-if="!isShared"
                color="#0BAAFF"
                class="white--text recording-buttons"
                @click="showShareRec = true"
                :disabled="(editing || !recLoaded || fileProcessing == 'uploading' || fileProcessing == 'processing' )"
                >
                <div class="btn-txt">{{$t('media.share')}}</div>
                <v-icon right dark>share</v-icon>
                </v-btn>

                <v-btn
                v-if="isShared"
                @click="showEditShareRec = true"
                color="success"
                class="white--text recording-buttons"
                :disabled="(editing || !recLoaded)"
                >
                <div class="btn-txt">{{$t('media.shared')}}</div>
                <v-icon right dark>{{shareIcon}}</v-icon>
                </v-btn>

                <!-- <v-btn
                color="#0BAAFF"
                class="white--text recording-buttons"
                @click="addHighlight()"
                :disabled="(editing || !recLoaded)"
                >
                <div class="btn-txt">{{$t('core.highlight')}}</div>
                <v-icon right dark>bookmark_outline</v-icon>
                </v-btn> -->

                <v-menu offset-y left v-if="windowWidth > 1264">
                <template v-slot:activator="{ on }">
                <v-btn
                color="#999999"
                class="white--text recording-buttons"
                v-on="on"
                >
                <div class="btn-txt">{{$t('media.size')}}</div>
                <v-icon right dark>desktop_windows</v-icon>
                </v-btn>
                </template>

                <v-list>
                    <div v-for="option in playerOptions" :key="option.value">
                    <v-list-item @click="setPlayerSize(option.value)" v-if="option.threshold < windowWidth ">
                            <v-list-item-action>
                            <v-icon>{{option.icon}}</v-icon>
                            </v-list-item-action>
                            <v-list-item-title>{{option.title}}</v-list-item-title>
                    </v-list-item>

                    </div>
                </v-list>

                </v-menu>
                <!-- <v-menu offset-y left>
                    <template v-slot:activator="{ on }">
                        <v-btn
                        color="#999999"
                        class="white--text recording-buttons"
                        v-on="on">
                        <div class="btn-txt speed-txt">{{showSpeed + "x"}}</div>
                            <v-icon right dark>speed</v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                    <v-list-item @click="setSpeed(option.value)" v-for="option in options" :key="option.value">
                            <v-list-item-title>{{option.title}}</v-list-item-title>
                    </v-list-item>
                    </v-list>
                </v-menu> -->

        <v-menu
        offset-y left>
        <template v-slot:activator="{ on }">
            <v-btn color="#999999" v-on="on" class="recording-buttons white--text">
                <div class="btn-txt">{{$t('actions.edit')}}</div>
                <v-icon>settings</v-icon>
            </v-btn>

        </template>

        <v-list>
        <v-list-item @click="showEditRecording = true">
                <v-list-item-title>{{$t('actions.rename')}}</v-list-item-title>
        </v-list-item>
                <v-list-item @click="showDeleteRecording = true">
                <v-list-item-title>{{$t('actions.delete')}}</v-list-item-title>
        </v-list-item>
        </v-list>

        </v-menu>
            </div>
<speed-slider></speed-slider>

        </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EditRecordingMD from './EditRecordingMD'
import DeleteRecordingMD from './DeleteRecordingMD'
import ShareRecMD from './ShareRecMD'
import EditShareRecMD from './EditShareRecMD'
import EventBus from './../../eventBus'
import SpeedSlider from './SpeedSlider'

export default {

    data () {
        return {
            showEditRecording: false,
            showEditShareRec: false,
            showDeleteRecording: false,
            showShareRec: false,
            showSpeed: 1.0,
            options: [
                    {title: '0.75x', value: 0.75 },
                    {title: '1x', value: 1.0},
                    {title: '01.25x', value: 1.25},
                    {title: '1.5x', value: 1.5},
                    {title: '1.75x', value:1.75},
                    {title: '2x', value: 2}
                ],
            playerOptions: [
                    {title: 'PIP', icon: 'picture_in_picture', value: 'pip', threshold: 1264 },
                    {title: 'small', icon:'featured_video', value: 'small',threshold: 1264 },
                    {title: 'large', icon: 'video_label', value: 'large',threshold: 1 },
                ]
        }
    },
    props: ["recDetails"],
       computed: {
        ...mapGetters({
            currTime: 'GET_CURRENT_TIME',
            editing: 'MODAL',
            windowWidth: 'WIDTH',
            expandView: 'EXPAND_VIEW',
            recLoaded: 'REC_LOADED',
            fileProcessing: 'REC_PROCESS_STATUS',
            devMode: 'DEV_MODE'
        }),
        shareIcon () {
            return this.isShared && this.recDetails.Protected ? "lock" : "public"
        },
        isShared () {
            return (this.recDetails.Shared && !this.recDetails.Expire) || (this.recDetails.Shared && this.recDetails.Expire && !this.shareExpired)
        },
        shareExpired () {

          if (this.recDetails.Shared && this.recDetails.ValidUntil) {

            const timeNow = Date.now()
            const timeExp = this.recDetails.ValidUntil * 1000

            return timeExp <= timeNow

          }

          return false
        }
    },
    methods : {
        addHighlight: function () {

            if (this.currTime < 120) {
                this.$store.dispatch('SET_SLIDER_RANGE', [0, 30])
            } else {
                this.$store.dispatch('SET_SLIDER_RANGE', [this.currTime, this.currTime + 30])
            }
                this.$store.dispatch('NEW_HL_MODAL')
        },
        setExpand: function (bool) {
                this.$store.dispatch('SET_EXPAND', bool)
        },
        setSpeed: function (speed) {
            EventBus.$emit("SET_SPEED", speed)
            this.showSpeed = speed
        },
        setPlayerSize: function (size) {

            EventBus.$emit('PLAYER_SIZE', size)

        }
    },
    components: {
        appEditRecording : EditRecordingMD,
        appDeleteRecording: DeleteRecordingMD,
        appShareRecording: ShareRecMD,
        appEditShareRecording: EditShareRecMD,
        SpeedSlider
    }
}
</script>


<style lang="scss" scoped>

@import "@/styles/abstracts/_variables";

.detail-card {
    margin: 0;
    border-radius: 0px 0px 40px 40px;
}
.title-subhead {
    margin-top: 0px;
}

.content {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0 0 0;
}

.details {
    flex-grow: 2;
    text-align: left;
    display: flex;
}

.details-title {
    display: flex;
    text-align: left;
}

.details-title-text {
    display: inline;
    margin: 10px 20px 0 0;
}

.interview-actions {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 1em 0;
}

.interview-actions > * {
    flex-grow: 2;
    margin: 0 0.4em;
}

.speed-txt {
    text-transform: lowercase;
}

.player-size--pip {

    #app .left {
        margin-left: 0;
    }

    .interview-actions .recording-buttons i {
        margin-left: 0px;
    }

    .btn-txt {
        display: none;
        visibility: hidden;
        width: 0;
        height: 0;
    }



}

@media (min-width: 1px) and (max-width: $breakpoint-medium) {

    .interview-actions {
        margin: 1em auto 0 auto;
    }
}

@media (min-width: 1px) and (max-width: 1600px) {

    #app .left {
        margin-left: 0;
    }

    .interview-actions .recording-buttons i {
        margin-left: 0px;
    }

    .btn-txt {
        display: none;
        visibility: hidden;
        width: 0;
        height: 0;
    }

}

.left {
    margin-left: 24px;
}

.action-row {
    margin-top: 24px;
}


.recording-buttons {
    border-radius: 4px;
}

.recording-buttons i {
    margin-left: 10px
}

</style>

