<template>
<transition appear name="slide-fade">

    <div id="container" class="view-content" v-bind:class="'player-size--' + playerSize">
    <warning-overlay v-if="error" class="overlay" icon="error" :message="errorMsg"></warning-overlay>
    <div class="optimizing-bar" v-if="(fileProcessing == 'processing' || fileProcessing == 'uploading') && mediaReady"></div>

    <div class="modals">
        <app-edit-recording v-if="showEditRecording" @close="showEditRecording = false"></app-edit-recording>
        <app-delete-recording v-if="showDeleteRecording" :recording="recording" @close="showDeleteRecording = false"></app-delete-recording>
        <app-delete-highlight v-if="showDeleteHighlight" @close="showDeleteHighlight = false" @deletehighlight="deleteHighlight" :hlForDeleting="hlForDeleting"></app-delete-highlight>
        <app-start-transcribe v-if="showStartTranscribe" @close="showStartTranscribe = false" @updateRec="updateRec()"></app-start-transcribe>
    </div>

    <div id="recording-content" v-if="!error" :class="mediaType">
        <div id="media-content" v-if="mediaReady">
            <div class="player-box" :class="{'mobile-audio' : mobile && mediaType == 'audio' }">
            <div class="uploading-badge" v-if="fileProcessing == 'uploading'">Uploading</div>
            <app-player :mediaURL="mediaURL" :fallbackURL="fallbackURL" :hlsURL="hlsURL" :playLocal="playLocal" :mediaType="mediaType" :recDetails="recording"></app-player>
            <player-controls :mediaType="mediaType"  :mobile="false" v-if="!mobile"></player-controls>
            </div>
            <app-media-controls :fileProcessing="fileProcessing"></app-media-controls>
        </div>

        <div id="info-box" v-if="infoLoaded == 1">

            <div class="content-tabs" v-if="false">
            <v-tabs
                v-model="activeTab"
                tabs
                light
                background-color="transparent"
                color="#0BAAFF"
                slider-color="#0BAAFF"
                active-class="tabs-active"
                show-arrows
                centered
            >
            <v-tab @click="show = 'transcript'">{{$t('core.transcript')}}</v-tab>
            <v-tab @click="show = 'notepad'">{{$t('core.notepad')}}</v-tab>
            <!-- <v-tab @click="show = 'flags'" v-if="recording.Flags"> {{$t('core.flags')}}</v-tab> -->
            <v-tab @click="show = 'highlights'"> {{$t('core.highlights')}}</v-tab>
        </v-tabs>
        </div>

            <transition appear name="slide-fade">

            <app-highlight-box v-if="show == 'highlights'" :highlights="highlights"></app-highlight-box>

            </transition>

            <app-transcript v-show="show == 'transcript'" v-if="(recording && cognitoInfo && showTranscript)"></app-transcript>
            <app-notepad v-show="show == 'notepad'"></app-notepad>
            <!-- <app-flags v-show="show == 'flags'"></app-flags> -->

            <div class="description" v-show="show == 'highlights' && !highlights">
                <i class="material-icons icon big-icon">bookmark_border</i>
                <h3>{{$t('helpText.noHighlights')}}</h3>

            </div>

        </div>

    </div>

    <div class="mobile-player-controls" v-if="mobile && recLoaded">
        <player-controls :mediaType="mediaType" :mobile="true"></player-controls>
    </div>

    </div>
</transition>
</template>

<script>
// import Highlight from './../components/recordings/Highlight'
import HighlightBox from './../components/recordings/HighlightBox'
import Transcript from './../components/recordings/Transcript'
import Notepad from './../components/recordings/Notepad'
//import Flags from './../components/recordings/Flags'
import MediaControls from './../components/recordings/MediaControls'
import {mapGetters} from 'vuex'
import DeleteHighlight from './../components/recordings/DeleteHighlightMD'
import EditRecordingMD from './../components/recordings/EditRecordingMD'
import DeleteRecordingMD from './../components/recordings/DeleteRecordingMD'
import StartTranscribeMD from '../components/recordings/StartTranscribeMD.vue'
import EventBus from './../eventBus'
import Player from './../components/recordings/Player'
import requests from './../requests'
import WarningOverlay from './../components/UI/WarningOverlay'
import PlayerControls from './../components/recordings/PlayerControls'

export default {
    data () {
        return {
            activeTab: 0,
            showhighlights: true,
            showDeleteHighlight: false,
            showStartTranscribe: false,
            showEditRecording: false,
            showDeleteRecording: false,
            hlForDeleting: {},
            show: 'transcript',
            showTranscript: true,
            filterTags: [],
            filteredHighlights : [],
            playerSize: 'small',
            mediaURL: '',
            hlsURL: '',
            fallbackURL: '',
            mediaType: 'video',
            playLocal: false,
            mediaAddr: process.env.VUE_APP_MEDIA_URL,
            mediaReady: false,
            loading: false,
            processpoll: {},
            stopPolling: false,
            error: false,
            errorMsg: "Error loading recording",
        }
        },
        computed : {
            ...mapGetters({
                highlights : 'HIGHLIGHTS',
                tags: 'TAGS',
                recording: 'REC_DETAILS',
                cognitoInfo: 'COGNITO_INFO',
                infoLoaded: 'REC_INFO_STATUS',
                recLoaded: 'REC_LOADED',
                readyTranscript: 'READY_TRANSCRIPT',
                windowWidth: 'WIDTH',
                expandView: 'EXPAND_VIEW',
                tranStatus: 'TRAN_STATUS',
                localRecURLs: 'LOCAL_REC_URLS',
                fileProcessing: 'REC_PROCESS_STATUS',
                mobileBrowser: 'IS_MOBILE'
            }),
            isSmall () {
                return (this.windowWidth < 950 || this.expandView == true) ? true : false
            },
            isMobile () {
                return (this.windowWidth < 600) ? true : false
            },
            mobile () {
                return this.windowWidth < 1000 || this.mobileBrowser
            }
     },
     methods : {
        setMediaLocation: function () {

            if (this.recording.UploadStatus == "IN_PROGRESS" || this.recording.EncodingStatus == "IN_PROGRESS") {

                this.playLocal = true
                this.getLocalMediaURL(this.recording)
                const status = this.recording.UploadStatus == "IN_PROGRESS" ? "uploading" : "processing"
                this.$store.dispatch("SET_REC_PROCESS_STATUS", status)
                this.pollProcessingStatus()

            } else if (this.recording.EncodingStatus == "COMPLETED") {
                this.playLocal = false
                this.createS3MediaURL(this.recording)
            }

            this.mediaReady = true

        },
        getLocalMediaURL: function (recording) {

            let i = this.localRecURLs.findIndex(urlObj => {
                return urlObj.collectionid == recording.CollectionID && urlObj.itemid == recording.ItemID
            })

            if (i >= 0) {
                this.mediaURL = this.localRecURLs[i].url
            } else {

                if (!recording.EncodingStatus) {
                    this.errorMsg = "Your recording was not uploaded."
                } else {
                    this.errorMsg = "Your recording is processing and not available yet."
                    this.$store.dispatch("SET_REC_PROCESS_STATUS", "processing")
                }
                this.error = true
            }

            if (recording.MediaType.startsWith('audio')) {
                this.mediaType = 'audio'

            } else if (recording.MediaType.startsWith('video')) {
                this.mediaType = 'video'

            } else {
                this.errorMsg = "Error loading recording"
                this.error = true
                return
            }

        },
        createS3MediaURL: function (recording) {

            let fTypePath = ''
            let fExt = ''

            if (recording.MediaType == 'audio/mp3') {
                fTypePath = "MP3"
                fExt = "mp3"
                this.mediaType = 'audio'
                this.fallbackURL = `${this.mediaAddr}${recording.UserID}/${recording.CollectionID}/${recording.ItemID}/raw_upload/media.mp3`

            } else if (recording.MediaType.startsWith('audio')) {
                fTypePath = "M4A"
                fExt = "m4a"
                this.mediaType = 'audio'
                this.fallbackURL = `${this.mediaAddr}${recording.UserID}/${recording.CollectionID}/${recording.ItemID}/raw_upload/media.m4a`

            } else if (recording.MediaType.startsWith('video')) {
                fTypePath = "CMAF"
                fExt = "mpd"
                this.mediaType = 'video'
                this.hlsURL = `${this.mediaAddr}${recording.UserID}/${recording.CollectionID}/${recording.ItemID}/${fTypePath}/media.m3u8`
                this.fallbackURL = `${this.mediaAddr}${recording.UserID}/${recording.CollectionID}/${recording.ItemID}/raw_upload/media.mp4`

            } else {
                this.errorMsg = "Error loading recording"
                this.error = true
                return
            }
            this.mediaURL = `${this.mediaAddr}${recording.UserID}/${recording.CollectionID}/${recording.ItemID}/${fTypePath}/media.${fExt}`
        },
        pollProcessingStatus: function() {

            if (this.fileProcessing == "processing" || this.fileProcessing == "uploading") {

                this.processPoll = setInterval(() => {
                    this.checkProgress()
                },10000)

            } else {
                clearInterval(this.processPoll)
                this.processPoll = null
            }
        },
        checkProgress: function () {

            requests.getRecording(this.recording.CollectionID, this.recording.ItemID)
            .then( response => {

                if (response.data.data.EncodingStatus == "COMPLETED") {

                    this.$store.dispatch("SET_REC_PROCESS_STATUS", "complete")
                    clearInterval(this.processPoll)
                    this.processPoll = null

                    this.notifyFinished()

                } else {
                    const status = response.data.data.UploadStatus == "COMPLETED" ? "processing" : "uploading"
                    this.$store.dispatch("SET_REC_PROCESS_STATUS", status)
                }
            })
        },
        notifyFinished: function () {

            EventBus.$emit('SHOW_MESSAGE', 'Upload Complete', 'success')

            return

            // this.$notify({
            //   group: 'recUpdate',
            //   title: 'Processing Finished',
            //   text: `Your recording has been uploaded and optimized successfully.`,
            //   duration: 10000
            // })

        },
        updateRec: function () {

            this.showStartTranscribe = false
            this.$store.dispatch('SET_CURRENT_RECORDING',this.$route.params).then(() => {
                this.generateHighlights()

            if (this.recording.TranscribeStatus == 'COMPLETED') {

                const payload = {
                    // userID: this.cognitoInfo.sub,
                    collID: this.$route.params.collID,
                    recID: this.$route.params.recID,
                    }

                this.$store.dispatch('SET_TRANSCRIPT', payload)
            }

            })

        },
        startDeleteHighlight: function (highlight) {

            this.showDeleteHighlight = true
            this.hlForDeleting = highlight

        },
        deleteHighlight: function (highlight) {

            this.$store.dispatch('DELETE_HIGHLIGHT', {
                "params": this.$route.params,
                "highID" : highlight.HighlightID,
            })

        },
        generateHighlights: function () {

            if (!this.highlights) {
                this.filteredHighlights = []
                return
            }

        let highlightList = [...this.highlights]
            highlightList.sort((a, b) => (a.TimeStart > b.TimeStart) ? 1 : -1)

            if (this.filterTags.length == 0 ) {
                    this.filteredHighlights = highlightList
            } else {
                        const filter = this.filterTags == [] ? this.tags : this.filterTags
                        let array = []
                                if (filter.length > 0) {
                                    filter.forEach(element => {
                                        highlightList.forEach(highlight => {
                                            if (highlight.Tags) {
                                                if (highlight.Tags.includes(element)) {
                                                    array.push(highlight)
                                                }
                                            }
                                        })
                                    })
                                }
                                this.filteredHighlights = [... new Set(array)]
                            }
                },
    },
    watch: {
        textSearch : function () {
            this.generateHighlights()
        },
        filterTags : function () {
            this.generateHighlights()
        },
        highlights : function () {
            this.generateHighlights()
        },

    },
    components: {
        appHighlightBox : HighlightBox,
        appTranscript: Transcript,
        appMediaControls: MediaControls,
        appDeleteHighlight: DeleteHighlight,
        appStartTranscribe: StartTranscribeMD,
        appNotepad: Notepad,
     //   appFlags: Flags,
        appEditRecording: EditRecordingMD,
        appDeleteRecording: DeleteRecordingMD,
        appPlayer: Player,
        WarningOverlay,
        PlayerControls
    },
    async beforeCreate() {

        this.$store.dispatch("SET_REC_PROCESS_STATUS", "")
        this.loading = true

        this.$store.dispatch('SET_CURRENT_RECORDING',this.$route.params).then(() => {
            this.generateHighlights()
            this.setMediaLocation()
            this.loading = false
        })

    },
    beforeDestroy () {
        this.$store.dispatch('SET_CURRENT_TIME', 0)
        this.$store.dispatch('CLEAR_CURR_RECORDING')
        this.$store.dispatch("SET_REC_PROCESS_STATUS", "")
        clearInterval(this.processPoll)
    },
    mounted () {

        window.addEventListener('beforeunload', () => {
            if (this.fileProcessing == "uploading") {
                alert("Your file is still uploading. You will lose it if you close this window. Proceed?");
            } else {
                return;
            }
        })

        EventBus.$on('START_TRANSCRIBE', () => {
            this.showStartTranscribe = true
        })

        EventBus.$on('PLAYER_SIZE', (size) => {
            this.playerSize = size
        })

        EventBus.$on('UPDATE_CURR_REC', () => {
            this.updateRec()
        })

        EventBus.$on('UPDATE_REC_PROCESS_STATUS', () => {
            this.checkProgress()
        })

        EventBus.$on("DELETE_HIGHLIGHT", highlight => {
            this.startDeleteHighlight(highlight)
        })

    },
}
</script>

<style scoped lang='scss'>
@import "@/styles/abstracts/_variables";

.rec-action-menu {
    margin-bottom: 1em;
}

#container {
    display: flex;
    flex-flow: column;
    align-items: center;
}

#recording-content {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

#info-box {
    padding: 1em;
    // margin-top: 1em;
    width: 100%;
    // border-left: 1px solid $hover-background;
}

#testfileinput{
    position: absolute;
    bottom: 0;
}

.mobile-audio.player-box {
    min-height: 0;
    width: 0;
    opacity: 0;
}

.player-box {
    position: relative;
    min-height: 90px;
    background-color: black;
}

.player-box  {
    border-radius: 4px;
    overflow: hidden;
}

@media (min-width: $breakpoint-large) {

    #recording-content.video {
        flex-flow: row;
        justify-content: flex-start;
        align-items: flex-start;
    }

    #recording-content.audio {
        width: 90%;
    }

    #info-box.video {
        padding: 0 3em 2em 1em ;
        flex-grow: 2;
        width: 65%;
    }

    #media-content {
        padding: 1em;
        // margin-top: 1.4em;
    }

}

@media (min-width: 1px) and (max-width: $breakpoint-large) {
    .rec-title-bar--menu {
        height: 0px;
        opacity: 0;
        position: absolute;
        left: 200em;
    }

    .rec-title-bar--menu-small {
        width: auto;
        height: auto;
        opacity: 1;
        position: inherit;
}
    // #media-content {
    //     padding: 0.2em;
    // }


@media (min-width: 1px) and (max-width: $breakpoint-medium) {
    .rec-title-bar {
        width: 100%;
        justify-content: center;
    }

    #media-content {
        margin: 0 auto;
    }

    #info-box {
        margin-bottom: 20em;
        margin-top: 0;
    }

    .content-tabs {
        margin-top: 0;
    }

}

}

#media-content {
    width: 100%;
    max-width: 750px;
    position: relative;
}


.audio #media-content {
    width: 100%;
    max-width: 1200px;
}


#hl-search-bar {
    width: 100%;
    margin: 0 auto;
    display: flex;
}

#hl-search-bar .search-box {
    width: 50%;
    margin: 0 15px;
}

#hl-search-bar .tag-box {
    width: 50%;
    margin: 0 15px;
}

.details-title {
    display: flex;
}

.details-title-text {
    display: inline;
    margin: 10px 20px 0 0;
}

.left {
    margin-top: 50px;
    margin-left: 48px;
}

.title-subhead {
    margin-top: 0px;
}

#summary-details h2 {
    text-align: left;
}

.player-size--pip #media-content {
    position: fixed;
    bottom: 1px;
    right: 1px;
    z-index: 9999;
    width: 600px;
    transition: all 0.3s ease-out;
}

.player-size--large #recording-content {
    width: 100%;
    max-width: auto;
}

.player-size--large #media-box, .player-size--large #media-content {
    width: 100%;
    padding: 1px;
    max-width: 1200px;
}

.player-size--large #recording-content.video  {
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.player-size--large #recording-content.audio {
    width: 90%;
}

 .player-size--pip #recording-content ,  .player-size--large #recording-content  {
     justify-content: flex-start;
     align-items: flex-start;
      display: flex;
     flex-flow: row wrap;
 }

 .player-size--pip #info-box {
    align-items: flex-start;
}

.player-size--large #info-box, .player-size--pip #info-box,  .player-size--large #media-content {
    width: 90%;
    /* max-width: 1100px; */
    margin: 0 2em;
}

.player-size--large .rec-title-bar--menu, .player-size--pip .rec-title-bar--menu {
        height: 0px;
        opacity: 0;
        position: absolute;
        left: 100em;
}

.player-size--large .rec-title-bar--menu-small,.player-size--pip .rec-title-bar--menu-small  {
        height: auto;
        opacity: 1;
        position: inherit;
}


.optimizing-bar {
    background: linear-gradient(20deg, rgb(0, 58, 99), #0BAAFF);
    background-size: 400% 400%;
    height: 6px;
    width: 100%;
    -webkit-animation: flash-bar 4s ease infinite;
    -moz-animation: flash-bar 4s ease infinite;
    animation: flash-bar 4s ease infinite;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 2;
}

.mobile-player-controls {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: black;
    min-height: 30px;
    transition: all 0.6s;
}

@-webkit-keyframes flash-bar {
    0%{background-position:0% 39%}
    50%{background-position:100% 62%}
    100%{background-position:0% 39%}
}
@-moz-keyframes flash-bar {
    0%{background-position:0% 39%}
    50%{background-position:100% 62%}
    100%{background-position:0% 39%}
}
@keyframes flash-bar {
    0%{background-position:0% 39%}
    50%{background-position:100% 62%}
    100%{background-position:0% 39%}
}

.overlay {
    text-align: center;
}

.uploading-badge {
    position: absolute;
    top: 0;
    left: 0;
    font-weight: 700;
    z-index: 1;
    color: $accent-color;
    padding: 0.5em 1em;
    animation: flash-badge 2s linear infinite;
    transition: all 0.3s;
}

@keyframes flash-badge {
    0%{color: $accent-color}
    50%{color: white}
    100%{color: $accent-color}
}


</style>
