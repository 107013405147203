<template>

    <div id="media-box">

        <v-slide-y-transition>
            <app-add-highlight v-if="displayModal" key="editingwindow"></app-add-highlight>
        </v-slide-y-transition>

        <app-recording-details v-if="recDetails" :recDetails="recDetails"></app-recording-details>

    </div>

</template>

<script>

// import Player from './Player'
import AddHighlight from './AddHighlight'
import RecordingDetails from './RecordingDetails'
import {mapGetters} from 'vuex'

export default {

        components: {
        // appPlayer : Player,
        appAddHighlight: AddHighlight,
        appRecordingDetails: RecordingDetails,
	},
	data() {
		return {
            showAddHighlight: false
		}
    }, 
    computed: {
        ...mapGetters({
            displayModal : 'MODAL',
            recDetails: 'REC_DETAILS',
            windowWidth: 'WIDTH',
        }),
        // isSmall () {
        //     return (this.windowWidth < 950) ? true : false
        // },
    }
};

</script>

<style scoped>

#media-box {
    display: flex;
    flex-flow: column;
}

.actions { 
    display: flex;
    flex-direction: column;
}

</style>


