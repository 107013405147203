<template>
<transition name="modal">
<div class="modal-mask">
    <div class="modal-wrapper">
        <div class="modal-container wide-modal" @click.stop="" @click="stopEditing">
            <div class="modal-title-bar">
                <h2 class="modal-header">{{$t('modal.editQuoteTitle')}}</h2>
                <v-btn  icon @click="close">
                    <div class="material-icons modal-title-close">clear</div>
                </v-btn>
            </div>
            <div class="modal-content" v-if="state == 'edit'">

                <div class="speaker-selector">

                    <v-select :items="speakerChoices"
                    outlined
                    single-line
                    prepend-inner-icon="person"
                    :item-text="'Label'"
                    :item-value="'Speaker'"
                    v-model="speakerSelected"
                    :value="speakerSelected.Speaker"
                    @change="speakerChanged = true"
                    hide-details
                    ></v-select>
                    <v-btn large class="listen-btn" @click="playToggle"><v-icon left>{{playerState == 'playing' ? 'stop' : 'play_arrow'}}</v-icon>{{playerState == 'playing' ? 'Stop' : 'Play'}}</v-btn>

                </div>

                <div class="editing-canvas" v-if="renderWords">

                    <div v-for="(word, index) in dialogue" :key="index" class="word-row">
                        <edit-word  :word="word" :index="index"></edit-word>
                        <new-word  v-if="!splitting" :beforeIndex="index" :length="dialogue.length"></new-word>

                    </div>

                </div>

        </div>

        <div v-if="state == 'confirmDelete'" class="modal-content">
            <h3>{{$t('modal.editQuoteDeleteMsg')}}</h3>
        </div>

        <div v-if="state == 'confirmMerge'" class="modal-content">
            <h3>{{$t('modal.editQuoteMergeMsg')}}</h3>
        </div>

        <div class="button-row">

            <!-- <div  class="button-row"> -->

                <v-btn @click="state = 'edit'" v-if="state == 'confirmDelete'">
                <div class="material-icons">keyboard_arrow_left</div>
                {{$t('actions.back')}}
                </v-btn>

                <v-btn color="success" @click="deleteQuote" v-if="state == 'confirmDelete'">
                <div class="material-icons">delete</div>
                {{$t('actions.delete')}}
                </v-btn>

            <!-- </div> -->


            <!-- <div > -->

                <v-btn @click="state = 'edit'" v-if="state == 'confirmMerge'">
                <div class="material-icons">keyboard_arrow_left</div>
                {{$t('actions.back')}}
                </v-btn>

                <v-btn color="success" @click="mergeAbove" v-if="state == 'confirmMerge'">
                <div class="material-icons">check</div>
                {{$t('actions.confirm')}}
                </v-btn>

                <v-btn @click="undo" :disabled="history.length == 0" v-if="state == 'edit'">
                <div class="material-icons">undo</div>
                <div class="modal-btn-hide">{{$t('actions.undo')}}</div>
                </v-btn>

                <v-btn @click="state = 'confirmDelete'" v-if="state == 'edit'" >
                <div class="material-icons">delete</div>
                <div class="modal-btn-hide">{{$t('actions.delete')}}</div>
                </v-btn>

                <v-btn @click="state = 'confirmMerge'" v-if="state == 'edit' && index != 0">
                <div class="material-icons">merge_type</div>
                <div class="modal-btn-hide">{{$t('actions.merge')}}</div>
                </v-btn>

                <v-btn color="success" :disabled="history.length < 1 && !speakerChanged" @click="saveDialogue" v-if="state == 'edit'">
                <div class="material-icons">save</div>
                <div class="modal-btn-hide">{{$t('actions.save')}}</div>
                </v-btn>

    </div>
    </div>
</div>
</div>
</transition>
</template>

<script>
import EditWord from './EditWord'
import NewWord from './NewWord'
import analytics from './../../analytics'
// import QuoteSplitter from './QuoteSplitter'
import requests from './../../requests'
import EventBus from './../../eventBus'
// import * as clipboard from 'clipboard-polyfill'

export default {
    props: ["quote", "index"],
    data () {
        return {
            dialogue: [],
            history: [],
            renderWords: true,
            speakerSelected: {},
            speakerChoices: [],
            speakerChanged: false,
            state: "edit",
            splitting: false,
            startTime: 0,
            finishTime: 0,
        }
    },
    components : {
        EditWord,
        NewWord,
        // QuoteSplitter,
    },
    computed : {
        recDetails () {
            return this.$store.getters.REC_DETAILS
        },
        speakers () {
            return this.$store.getters.SPEAKERS
        },
        speakerNames() {
            return this.$store.getters.SPEAKER_NAMES
        },
        playerState() {
            return this.$store.getters.PLYR_STATE
        }
    },
    methods: {
        forceRerender: function () {
        // Remove my-component from the DOM
            this.renderWords = false;

            this.$nextTick(() => {
                this.renderWords = true;
            })
        },
        stopEditing: function () {
            EventBus.$emit("WORD_SELECTED")
            EventBus.$emit("SPLIT_START")
        },
        deleteQuote: function () {

            requests.deleteTranscriptQuote(this.recDetails.CollectionID, this.recDetails.ItemID, this.quote.ID)
            .then(response => {
                if (response.status == 200) {
                    this.$store.dispatch("UPDATE_TRANSCRIPT", response.data.data.Transcript)
                    EventBus.$emit("REFRESH_TRANSCRIPT")
                    analytics.gaTranscript("editQuote", "Delete")
                    this.$emit('close')
                }
            })

        },
        mergeAbove: function () {

            requests.mergeTranscriptQuote(this.recDetails.CollectionID, this.recDetails.ItemID, this.quote.ID - 1)
            .then(response => {
                if (response.status == 200) {
                    this.$store.dispatch("UPDATE_TRANSCRIPT", response.data.data.Transcript)
                    EventBus.$emit("REFRESH_TRANSCRIPT")
                    analytics.gaTranscript("editQuote", "Merge")
                    this.$emit('close')
                }
            })

        },
        startSplit: function () {

            this.splitting = !this.splitting

        },
        splitQuote: function (index) {

            this.createSplits(index)

            EventBus.$emit("SPLIT_START")
        },
        createSplits: function(index) {

            let dialogues = this.makeSplitDialogue(index)

            const firstTime = this.getTimeStart(dialogues[0])
            const  secondTime = this.getTimeStart(dialogues[1])

            const firstQuote = {
                ID: this.quote.ID,
                Speaker: this.speakerSelected,
                Dialogue: dialogues[0],
                TimeStart: firstTime
            }

            const secondQuote = {
                ID: this.quote.ID,
                Speaker: this.speakerSelected,
                Dialogue: dialogues[1],
                TimeStart: secondTime
            }

            const updateObj = {
                ID: this.quote.ID,
                Quotes: [firstQuote, secondQuote]
            }

            requests.splitTranscript(this.recDetails.CollectionID, this.recDetails.ItemID, updateObj)
            .then(response => {

                if (response.status == 200) {

                    updateObj.index = this.index
                    this.$store.dispatch("UPDATE_TRANSCRIPT", response.data.data.Transcript)
                    EventBus.$emit("REFRESH_TRANSCRIPT")
                    analytics.gaTranscript("editQuote", "Split")
                    this.$emit('close')
                }

            })

        },
        makeSplitDialogue: function (index) {

            let firstDiag = []
            let secondDiag = []
            let i = 0

            for (i = 0; i < this.quote.Dialogue.length; i++) {

                if (i <= index) {
                    firstDiag.push(this.quote.Dialogue[i])
                } else {
                    secondDiag.push(this.quote.Dialogue[i])
                }

            }

            return [firstDiag, secondDiag]

        },
        getTimeStart: function (dialogue) {

            const firstWord = dialogue.find(word => {
                return word.T
            })

            return firstWord.T

        },
        close: function () {
            this.$emit('close')
        },
        deleteWord (index) {

            this.dialogue.splice(index, 1)
            this.updatedWord()

        },
        createWordTime(update) {

            let newTime = 0
            let aftTime = null
            // is there a index before and after
            let befTime = this.dialogue[update.index].T

            if (this.dialogue[update.index + 1]) {
                 aftTime = this.dialogue[update.index + 1].T
            }

            if (befTime && aftTime) {

                return (parseFloat(befTime) + (parseFloat(aftTime) - parseFloat(befTime)) / 2).toString()

            }

            let found = false
            let i = update.index - 1
            let closestBef = 0

            // find closest word with a time BEFORE this one
            while (!found && i >= 0) {
                if (this.dialogue[i].T) {
                    closestBef = this.dialogue[i].T
                    found = true
                }
                i--
            }

            if (found) {
                newTime = closestBef
            }

            if (!found) {
                newTime = this.quote.TimeStart
            }

            return newTime

        },
        newWord (update) {

            const newTime = this.createWordTime(update)

            const newWord = {
                W: update.word,
                T: newTime
            }

            this.dialogue.splice(update.index + 1, 0, newWord)

            this.updatedWord()

        },
        editWord (update) {

            this.dialogue.splice(update.index, 1, update.word)
            this.updatedWord()

        },
        updatedWord () {
            this.forceRerender()
        },
        saveHistory () {

            const histObj = JSON.parse(JSON.stringify(this.dialogue))

            this.history.push(histObj)
        },
        undo: function () {

            const latest = this.history.pop()

            this.dialogue = JSON.parse(JSON.stringify(latest));
            this.forceRerender()
        },
        getDisplayName: function () {

            let name = ""

            if (!this.speakerNames) {
                name = this.$options.filters.speakerize(this.speakerSelected)
            } else {
                this.speakerNames.forEach(aliasObj => {

                    if (aliasObj.Speaker == this.speakerSelected) {

                            if (aliasObj.Alias != "") {
                                name = aliasObj.Alias
                            } else {
                                name = this.$options.filters.speakerize(aliasObj.Speaker)
                    }

                    }
                })
            }

            return name

        },
        saveDialogue: function () {

            const newQuote = {
                ID: this.quote.ID,
                Speaker: this.speakerSelected,
                TimeStart: this.quote.TimeStart,
                Dialogue: this.dialogue,
                DisplayName: this.getDisplayName()
            }

            requests.editTranscript(this.recDetails.CollectionID, this.recDetails.ItemID, newQuote)
            .then(response => {

                if (response.status == 200) {

                    const updateObj = {
                        index: this.index,
                        quote: newQuote
                    }

                    const update = JSON.parse(JSON.stringify(updateObj));

                    this.$store.dispatch("UPDATE_TRANSCRIPT_QUOTE", update)
                    EventBus.$emit("REFRESH_TRANSCRIPT")
                    analytics.gaTranscript("editQuote", "Edit")
                    this.$emit('close')

                }

            })

        },
        setTimes: function () {

            let times = []

            let words = this.quote.Dialogue.filter((word) => {
                return word.T
            })

            let lastEl = words.pop()

            this.startTime = times[0] = Math.floor(parseInt(this.quote.TimeStart), 1)
            this.finishTime = times[1] = Math.ceil(parseInt(lastEl.T), 1)

        },
        playToggle() {

            if (this.playerState == "playing") {
                EventBus.$emit("PLYR_PAUSE")
            } else {
                EventBus.$emit("PLAY_INTERVAL", [this.startTime, this.finishTime + 1])
            }


        }
    },
    mounted () {
        this.dialogue = JSON.parse(JSON.stringify(this.quote.Dialogue));

        if (this.speakerNames) {

            this.speakerNames.forEach( speakerName => {

                let label = ""

                if (speakerName.Alias) {
                    label = speakerName.Alias
                } else {
                    label = this.$options.filters.speakerize(speakerName.Speaker);
                }

                const spkr = {
                    "Speaker" : speakerName.Speaker,
                    "Alias": speakerName.Alias,
                    "Label" : label
                }

                this.speakerChoices.push(spkr)

                if (speakerName.Speaker == this.quote.Speaker) {
                    this.speakerSelected = spkr.Speaker
                }

            })

        } else {

            this.speakers.forEach( speaker => {

                const spkr = {
                    "Speaker" : speaker,
                    "Alias": "",
                    "Label": this.$options.filters.speakerize(speaker)
                }

                this.speakerChoices.push(spkr)

                if (speaker == this.quote.Speaker) {
                    this.speakerSelected = spkr.Speaker
                }

            })

        }

        this.setTimes()

        EventBus.$on("WORD_EDIT", update => {
            this.saveHistory()
            this.editWord(update)
        })

        EventBus.$on("WORD_NEW", update => {
            this.saveHistory()
            this.newWord(update)
        })

        EventBus.$on("WORD_DELETE", index => {
            this.saveHistory()
            this.deleteWord(index)
        })

        EventBus.$on("SPLIT_QUOTE", index => {
            this.splitQuote(index)
        })

    },

}
</script>

<style scoped>

/* .modal-container {
    max-width: 800px;
} */

.editing-canvas {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    border: 2px solid #4a4a4a;
    border-radius: 4px;
    padding: 1em ;
    font-size: 1.2em;
    font-family: "consolas", monospace, monospace;
    overflow: auto;
    max-height: 60%;
}

.word-row {
    display: flex;
    align-items: center;
}

.speaker-selector {
    display: flex;
    align-items: center;
    margin-bottom: 1.5em;
}

.listen-btn {
    margin-left: 1em;
}


</style>
