<template>
<transition name="modal">
  <div class="modal-mask" @click="$emit('close')">
      <div class="modal-wrapper">
        <div class="modal-container" @click.stop="">

          <div class="modal-title-bar">
                  <h2 class="modal-header">{{$t('modal.downloadCaptionsTitle')}}</h2>
                  <v-btn icon @click="$emit('close')">
                  <div class="material-icons modal-title-close">clear</div>
              </v-btn>
          </div>

          <div class="modal-content">
              <div>
                <v-select
                solo
                :items="options"
                item-text="title"
                item-disabled="disabled"
                label="Select file format"
                @change="selected"
                return-object
                ></v-select>
              </div>

              <div class="styles">
                  <v-expansion-panels light>
                    <v-expansion-panel>
                    <v-expansion-panel-header>Caption Styles</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      {{$t('recordings.styleComingSoon')}}
                    </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>

              </div>
            </div>

            <div class="button-row">

              <v-btn @click="$emit('close')">
              {{$t('actions.close')}}
              </v-btn>

              <v-btn @click="downloadCaptions" class="focus-button"
              color="blue"
              light
              :disabled="!selectedOption.title != ''"
              >
              <v-icon left>get_app</v-icon>
              {{$t('actions.download')}}
              </v-btn>

            </div>

          </div>

        </div>
      </div>
</transition>
</template>

<script>
import requests from './../../requests'
import fileDownload from 'js-file-download'
import EventBus from './../../eventBus'

export default {
  props: ['recording'],
  data () {
      return {
        options : [
          {title: 'WebVTT (.vtt)', subtitle: '.vtt file', id: 'vtt', disabled: false, cache: ''},
          {title: 'SubRip (.srt) coming soon!', subtitle: '.srt file (coming soon)', id: 'srt', disabled: true, cache: ''},
          {title: 'SSA (SubStation Alpha) coming soon!', subtitle: '.srt file (coming soon)', id: 'srt', disabled: true, cache: ''},
        ],
        selectedOption : {}
      }
  },
  methods : {
    downloadCaptions: async function () {

      if (this.selectedOption.disabled || !this.selectedOption.title) {return}

      if (this.selectedOption.cache != '') {
        fileDownload(this.selectedOption.cache, `${this.recording.Name}.vtt`)
        return
      }

      try {
          const response = await requests.downloadCaptions(this.recording)
          fileDownload(response.data, `${this.recording.Name}.vtt`)
          this.selectedOption.cache = response.data
      } catch (error) {
          EventBus.$emit('SHOW_MESSAGE', 'Unable to download captions', 'red')
      }

    },
    selected: function (option) {
      this.selectedOption = option
    }

  },
}
</script>

<style scoped lang="scss">

// .options {
//   display: flex;
//   flex-flow: column;
//   align-items: center;
//   justify-content: center;
// }

// .option-container {
//   width: 100%;
//   position: relative;
//   margin: 1em;
// }

// .option-card {
//   display: flex;
//   width: 100%;
//   height: 100%;
//   border: 1px solid black;
//   border-radius: 4px;
//   justify-content: space-between;
//   align-items: center;
//   cursor: pointer;
//   position: relative;
//   padding: 0.5em 1em;

// }

// .option-title {
//   font-weight: 700;
//   font-size: 1.3em;
// }

// .option-left {
//   display: flex;
//   align-items: center;
// }

// .disabled-mask {
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   top: 0;
//   left: 0;
//   // border: 1px solid red;
//   background: rgba(255,255,255,0.2);
//   z-index: 99;
//   // pointer-events: none;
// }

.styles {
  margin-bottom: 1em;
}



</style>
