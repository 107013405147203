<template>
    <div class="speed-slider" @wheel.prevent="wheelScroll($event)">
    <div class="speed-label">{{$t('recordings.speedControl')}}</div>
      <v-slider
        class="slider-range"
        v-model="sliderVal"
        prepend-icon="speed"
        :min="min"
        :max="max"
        :step="step"
        :disabled="playerLoading"
        thumb-label
        :label="speedLabel"
        hide-details
      ></v-slider>
    <div >{{max + 'x'}}</div>


    </div>

</template>

<script>
import EventBus from './../../eventBus'
import analytics from './../../analytics'

export default {
    data () {
        return {
            sliderVal: 1,
            scrollLock : false,
            min: 1,
            max: 5,
            anaylticsTimer: {},
            anaylticsReady: true,
            step: 0.25
        }
    },
    computed: {
        speedLabel () {
            return (this.sliderVal).toFixed(2).toString() + "x"
        },
        playerLoading () {
            return this.$store.getters.PLYR_STATE == 'loading' ? true : false
        }
    },
    watch: {
        sliderVal: function() {

            if (this.sliderVal >= this.min && this.sliderVal <= this.max) {
                EventBus.$emit('SET_SPEED', parseFloat((this.sliderVal).toFixed(2)))
                this.fireEvent()
            }

        }
    },
    mounted () {

        let plyrSettings = JSON.parse(this.$ls.get('plyr'))

        if (plyrSettings.speed > 1) {
            this.sliderVal = plyrSettings.speed
        } else if (plyrSettings.speed > 1) {
            this.sliderVal = 1
        }


    },
    methods: {
        fireEvent: function () {

            if (!this.anaylticsReady) {
                return
            }

            analytics.gaRecording("speedSlider", this.sliderVal)

            this.anaylticsTimer = setTimeout(() => {
                this.anaylticsReady = true
            }, 20000)

            this.anaylticsReady = false

        },
        wheelScroll: function (event) {
            event.deltaY < 0 ? this.sliderVal +=this.step : this.sliderVal -=this.step
        },

    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/abstracts/_variables.scss";

.speed-slider {
    cursor: pointer;
    padding: 0.5em;
    // border: 1px solid #4a4a4a30;
    border-radius: 4px;
    margin: 0.8em 0.3em;
    display: flex;
    // flex-flow: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
}

.no-touch .speed-slider:hover {
    background: white;
    box-shadow: $modal-shadow;
    // border: 1px solid #4a4a4a
}

// .no-touch .speed-slider:hover .speed-label {
//     opacity: 1;
// }

.speed-label {
    font-size: 1em;
    font-weight: bold;
    // color: $accent-color-dull;
    // opacity: 0;
    transition: 0.3s all;
    margin-right: 0.3em;
    // margin-top: -0.5em;
}

.no-touch .speed-label:hover {
    background: white;
}

.slider-range {
    width: 100%;
}



</style>

<style lang="scss">

 .speed-slider .v-slider__thumb, .v-slider__thumb:before {
    position: absolute;
    border-radius: 20%;
    -webkit-transition: .3s cubic-bezier(.25,.8,.5,1);
    transition: .3s cubic-bezier(.25,.8,.5,1);
}

.speed-slider .v-slider__thumb {
    height: 16px;
    width: 8px;
}

</style>

