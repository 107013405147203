<template>
<div :class="{'mobile' : mobile}">
<div id="player-controls" class="no-highlight" :class="state" @mouseenter="hover = true" @mouseleave="hover = false">

    <div class="player-controls__hl-playing" v-if="highlightPlaying">{{$t('media.playing')}}: {{highlightPlaying.Name}}</div>

    <div class="player-controls__playback">
        <div class="material-icons player-controls__button  saylient-btn" @click="rewind(10)" v-tooltip="{ content: 'Rewind 10s' , classes: 'app-tooltip'}">fast_rewind</div>
        <div class="material-icons player-controls__button play saylient-btn" @click="playToggle">{{plyrState == 'playing' ? 'pause' : playIcon}}</div>
        <div class="material-icons player-controls__button  saylient-btn" @click="fastForward(10)" v-tooltip="{ content: 'Forward 10s' , classes: 'app-tooltip'}">fast_forward</div>
    </div>

    <div class="player-controls__timer no-highlight">
        {{currentTime | timeFormat}} / {{mediaDuration | timeFormat}}
    </div>

    <div id="seekbar" class="player-controls__seekbar" :class="hover || dragging ? 'hover' : ''"
        @mousemove="hoverSeeker"
        @mouseleave="hover = false"
    >
    <input type="range" min="0" :max="mediaDuration" v-model="value" step="0.000001" class="slider" id="myRange"
        @input="changeSlider"
        @mousedown="dragging = true"
        @mouseup="dragging = false"
        @touchstart="dragging = true"
        @touchend="dragging = false"
    >
        <div class="seekbar__duration">

            <div class="seekbar__timestamp" v-bind:style="{left: timestampLeft + '%' }"
            :class="dragging ? 'dragging' : '' ">{{value | timeFormat}}</div>

            <div class="seekbar__timestamp" v-bind:style="{left: xPosLeft + '%' }" v-show="state == ''"
            :class="hover && !dragging ? 'hover' : '' ">{{xPosLeft / 100 * mediaDuration | timeFormat}}</div>

            <div class="seekbar__current-time"  v-bind:style="{width: percentPlayed * 100 + '%' }"></div>
            <highlight-slider v-if="editingHighlight"></highlight-slider>
            <highlight-bar class="hl-bar"></highlight-bar>
            <!-- <flag-bar :videoDuration="mediaDuration"></flag-bar> -->

        </div>
    </div>

    <div class="player-controls__fs" v-if="mediaType != 'audio'">
        <div class="material-icons player-controls__button saylient-btn" v-tooltip="{ content: 'Fullscreen' , classes: 'app-tooltip'}" @click="fullScreen">fullscreen</div>
        <div v-if="recDetails.Captions" class="material-icons player-controls__button saylient-btn" :class="{ 'active-btn' : showingCaptions}" v-tooltip="{ content: 'Captions' , classes: 'app-tooltip'}" @click="toggleCaptions">subtitles</div>
    </div>

</div>
</div>
</template>

<script>
import EventBus from './../../eventBus'
import HighlightBar from './HighlightBar'
import HighlightSlider from './HighlightSlider'
//import FlagBar from './FlagBar'
import analytics from './../../analytics'

export default {
    data () {
        return {
            value: 0,
            dragging: false,
            hover: false,
            touchHover: false,
            xPos: 0,
            showingCaptions: false,
            hasLoaded: false,
        }
    },
    computed : {
        currentTime () {
            return this.$store.getters.GET_CURRENT_TIME
        },
        mediaDuration () {
            return this.$store.getters.GET_DURATION
        },
        plyrState () {
            return this.$store.getters.PLYR_STATE
        },
        highlightPlaying () {
            return this.$store.getters.HL_PLAYING
        },
        highlightHover () {
            return this.$store.getters.HL_HOVER
        },
        percentPlayed () {
            return this.currentTime / this.mediaDuration
        },
        timestampLeft () {
            return this.value / this.mediaDuration * 95
        },
        xPosLeft () {
            return this.xPos * 100
        },
        editingHighlight () {
            return this.$store.getters.MODAL
        },
        state () {

            if (this.mediaDuration <= 0) {
                return 'loading'
            } else if (this.editingHighlight) {
                return 'editing'
            } else if (this.hover || this.dragging) {
                return 'hover'
            } else {
                return ''
            }
        },
        playIcon () {
            // return this.mobile ? "play_circle_outline" : "play_arrow"
            return "play_arrow"
        },
        devMode () {
            return this.$store.getters.DEV_MODE
        },
        recDetails () {
            return this.$store.getters.REC_DETAILS
        }

    },
    mounted () {
        this.value = this.mediaDuration

        EventBus.$on('VIDEO_HOVER', (type) => {
            type == 'enter' ? this.hover = true : this.hover = false
        })

    },
    props: ["mediaType", "mobile", "share"],
    watch: {
        currentTime: function() {
            if (!this.dragging) {
                this.value = this.currentTime
            }
        },
        plyrState: function () {
            if (this.plyrState == 'ready' && !this.hasLoaded) {

                setTimeout(()=> {
                    this.toggleCaptions()
                    this.hasLoaded = true
                }, 3000)

            }
        }

    },
    methods: {
        hoverSeeker () {
            this.xPos = event.offsetX / document.querySelector('#seekbar').offsetWidth
            this.hover = true
        },
        setRange: function (value) {

            this.value = value
        },
        changeSlider: function () {
            EventBus.$emit('PLYR_SEEK_TO', this.value)
        },
        playToggle: function () {
            EventBus.$emit('PLYR_TOGGLE')
        },
        fullScreen: function () {
            EventBus.$emit('PLYR_FULLSCREEN')
        },
        rewind: function(time) {
            EventBus.$emit('PLYR_SEEK_TO', this.currentTime - time)
        },
        fastForward: function(time) {
            EventBus.$emit('PLYR_SEEK_TO', this.currentTime + time)

        },
        toggleCaptions: function () {

            if (this.recDetails.Captions) {

                if (this.mediaType != "audio") {
                    EventBus.$emit('PLYR_TOGGLE_CAPTIONS')
                    this.showingCaptions = !this.showingCaptions
                    analytics.gaRecording('toggleCaptions', this.recDetails)
                }
            }
        }
    },
    components: {
        HighlightBar,
        HighlightSlider,
 //       FlagBar,
      //  SpeedSlider
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/abstracts/_variables";

#player-controls .active-btn {
    color: $accent-color;
}

.mobile #player-controls.loading {
    transition: all 1s;
    overflow: hidden;
    bottom: -200px;
}

#player-controls.loading {
    opacity: 0;
}

.mobile #player-controls, .mobile #player-controls.hover {
    background-color: black;
    padding: 1.5em 1em ;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    box-shadow: 0px -6px 14px #00000020, 0px -1px 3px #00000060;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 15;
    transition: all 1s;
}

#player-controls {
    opacity: 0;
}

#player-controls.hover, #player-controls.editing, .mobile #player-controls {
    opacity: 1;
}

#player-controls.hover {
    background-color: #00000090;

}

#player-controls.editing {
    background-color: #000000;
}

#player-controls {
    background-color: #00000030;
    padding: 0.5em 0.5em ;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    width: 100%;
    transition: all 0.6s;
}

.player-controls__hl-playing {
    position: absolute;
    bottom: 8px;
    left: 0;
    width: 100%;
    color: #0BAAFF;
    font-size: 0.7em;
    text-align: center;
}

.player-controls__playback {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 14px;
}

.player-controls__timer {
    font-size: 1em;
    font-weight: 700;
    color: white;
}


.player-controls__seekbar {
    flex-grow: 2;
    height: 1em;
    color: white;
    display: flex;
    align-items: flex-end;
    position: relative;
    cursor: pointer;
}

.player-controls__button {
    color: white;
    font-size: 1.5em;
    margin: 0 0.1em;
    border-radius: 100%;
}

.player-controls__button.play {
    font-size: 2em;
    /* margin: 0 0.2em 0 0; */
}

.seekbar__duration {
    width: 97%;
    height: 3px;
    position: absolute;
    bottom: 6px;
    left: 6px;
    background-color: #ffffff20;
    transition: all 0.3s;
}

.hover .seekbar__duration {
    height: 8px
}

.seekbar__current-time {
    height: 100%;
    background-color: #ffffff80;
    box-shadow: #ffffff80;
    opacity: 0.8;
    transition: 0.2s all;
}

.seekbar__timestamp {
    position: absolute;
    left: 0;
    bottom: 1.6em;
    font-weight: 700;
    font-size: 14px;
    opacity: 0;
    transition: 0.4s opacity;

}

.mobile {

    .player-controls__button.play {
    font-size: 2em;
    }

    .player-controls__button {
    font-size: 1.2em;
    }

    .player-controls__timer {
    font-size: 0.8em;
    }

}

.mobile .seekbar__timestamp {
    bottom: 3.5em;
    padding: 0.5em 1em;
    background-color: black;
    border-radius: 4px;
}

.mobile .hover.seekbar__timestamp {
    opacity: 0;
    display: none;
    visibility: hidden;
}

.hover.seekbar__timestamp {
    opacity: 0.6;
}

.dragging.seekbar__timestamp {
    opacity: 1;
}

.slider {
    z-index: 2;
    cursor: pointer;
    height: 2em;
}

input[type=range] {
-webkit-touch-callout: none;
-webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}

input[type=range]::-webkit-slider-thumb {
    transition: all 0.3s;
}

.hover input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  opacity: 1;
  height:18px;
  width: 18px;
}

.hover input[type=range]::-moz-range-thumb {
  opacity: 1;
  height:12px;
  width: 12px;
}


input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height:1px;
  width: 1px;
  opacity: 0;
  border-radius: 100%;
  background:#ffffff;
  cursor: pointer;
  margin-top: 10px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  box-shadow: 0px 0px 2px #ffffff80;
}

input[type=range]::-moz-range-thumb {
  -webkit-appearance: none;
  height:1px;
  width: 1px;
  opacity: 0;
  border-radius: 100%;
  background:#ffffff;
  cursor: pointer;
}

input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;
}





</style>