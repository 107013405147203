<template>
      <transition name="modal">
    <div class="modal-mask"
        @click="$emit('close')"
        >
      <div class="modal-wrapper">
        <div class="modal-container"
        @click.stop="">

    <div class="modal-title-bar">
            <h2 class="modal-header">{{$t('modal.startTranTitle')}}</h2>
            <v-btn icon @click="$emit('close')">
            <div class="material-icons modal-title-close">clear</div>
        </v-btn>
    </div>

    <div class="modal-content">

    <h3 class="modal-sub-header">{{$t('modal.startTranText')}}</h3>

    <div class="select-speakers">

      <v-select class="form-field"
        :label="$t('modal.startTranSpkrLabel')"
        :items="speakerItems"
        prepend-inner-icon="people"
        outlined
        :placeholder="$t('modal.startTranSpkrPlaceholder')"
        v-model="speakers"
      ></v-select>
    </div>

    <div class='transcribe-status'>
    <transcribe-status :leftoverQuota="leftoverQuota" :remainingQuota="remainingQuota"></transcribe-status>

    </div>


      <!-- <div class="transcribe-select">

        <div class="transcribe-quota">
          <i aria-hidden="true" class="v-icon material-icons theme--light green-text">chat</i>
          <p>You have</p>
          <p class="bold">{{remainingQuota}}</p>
          <p> free transcription minutes</p>
        </div>

        <div class="transcribe-quota" v-show="leftoverQuota > 0">
          <i aria-hidden="true" class="v-icon material-icons theme--light green-text">done</i>
          <p>You will have</p>
          <p class="bold">{{leftoverQuota}}</p>
          <p>minutes remaining</p>
        </div>

        <div class="transcribe-quota" v-show="leftoverQuota < 0">
          <i aria-hidden="true" class="v-icon material-icons theme--light green-text">error_outline</i>
          <p>This will exceed your quota by</p>
          <p class="bold">{{-leftoverQuota}} minutes</p>
        </div>


        <div class="transcribe-quota" v-if="(accountMode == 'TRIAL' && leftoverQuota < 0)">
          <i aria-hidden="true" class="v-icon material-icons theme--light green-text">lock_open</i>
          <p>Sign up to a </p>
          <p class="bold">paid plan</p>
          <p>to transcribe this recording</p>
        </div>


          <div class="transcribe-quota" v-show="extraCost > 0">
          <i aria-hidden="true" class="v-icon material-icons theme--light green-text">attach_money</i>
          <p> An additional cost of</p>
          <p class="bold">{{ "$" + extraCost.toFixed(2) }} </p>
          <p> USD </p>
          <p>will be added to your next bill</p>
        </div>

      </div> -->





    </div>

        <div v-if="(accountMode == 'TRIAL' && leftoverQuota < 0)" class="button-row">
            <v-btn
            @click="$emit('close')"
            >
            {{$t('actions.cancel')}}
            </v-btn>

            <v-btn
            class="focus-button"
            color="#0BAAFF"
            @click="$router.push('/Account')"
            >{{$t('modal.startTranSeePlans')}}</v-btn>
        </div>

        <div v-else class="button-row">
            <v-btn
            @click="$emit('close')"
            >
            {{$t('actions.cancel')}}
            </v-btn>

            <v-btn
            class="focus-button"
            color="#0BAAFF"
            @click="startTranscribing"
            :disabled="!speakers"
            >{{$t('modal.startTran')}}</v-btn>
    </div>




          </div>
        </div>
      </div>
  </transition>
</template>

<script>
// import EventBus from './../../eventBus'
import {mapGetters} from 'vuex'
import requests from '../../requests'
import TranscribeStatus from './../collections/TranscribeStatus'


export default {
  data () {
    return {
      speakerItems: [1,2,3,4,5,6,7,8,9,10],
      speakers: null,
    }
  },
  components: {
    TranscribeStatus
  },
  computed :{
    ...mapGetters({
        details: 'REC_DETAILS',
        vocabulary: 'GET_VOCABULARY',
        userDetails: 'USER_DETAILS',
        currentTranscribeQuota: 'CURRENT_DURATION_UPLOADING',
        userPlan: 'USER_PLAN',
        accountMode: 'ACCOUNT_MODE',
    }),
    // acceptMessage () {

    //   return this.leftoverQuota < 0
    //     ? "Accept Charge and Start Transcription"
    //     : "Start Transcription"

    // },
    pendingQuota () {
      return this.userDetails.PendingTranscribeQuota
    },
    usedQuota () {
      return this.userDetails.UsedTranscribeQuota
    },
    totalQuota () {
      return this.userDetails.TranscribeQuota
    },
    remainingQuota () {
      return this.totalQuota - (this.pendingQuota + this.currentTranscribeQuota)
    },
    leftoverQuota () {

      if (this.duration > 0) {
        let mins = Math.ceil(this.duration / 60)
        return this.remainingQuota - mins
      } else {
        return null
      }

    },
    // additionalTranscriptionCost () {
    //   return this.userPlan.ExtraTranscriptionCharge
    // },
    // exceededNoExtra () {
    //   return !this.userPlan.AllowExtraTranscription && this.leftoverQuota < 0 ? true : false
    // },
    // extraCost () {

    //   if (this.leftoverQuota < 0 ) {
    //       return Math.abs(Math.round(this.leftoverQuota * this.additionalTranscriptionCost * 10) / 10)
    //   } else {
    //     return 0
    //   }

    // },
    duration () {
      return this.details.MediaDuration
    },
    // prettyDuration () {

    //   let mins = Math.ceil(this.duration / 60)

    //   if (mins == 1) {
    //       return mins + " minute"
    //   } else if (mins > 1) {
    //       return mins + " minutes"
    //   } else {
    //     return ""
    //   }
    // },
  },
  methods : {
    startTranscribing: function () {

      const payload = {
        "collectionID": this.details.CollectionID,
        "recordingID": this.details.ItemID,
        "speakers": this.speakers
      }

      requests.startTranscribing(payload)
      this.$emit('updateRec')

    }
  }
}

</script>

<style scoped lang="scss">

.modal-content {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.modal-sub-header {
  margin-bottom: 2em
}
// .transcribe-select {
//   display: flex;
//   flex-flow: row wrap;
//   align-items: center;
//   justify-content: flex-start;
//   /* padding: 0 20px; */
//   /* border-bottom: 1px solid #aaaaaa; */
//   /* margin: 36px 24px 0 0; */
//   border: 1px solid red;
// }


// .transcribe-select p {
//   color: rgba(0,0,0,.82);
//   font-size: 16px;
//   font-family: Helvetica, Arial, sans-serif;
//   text-align: left;
//   margin: 0 10px;
// }

// .transcribe-select .transcribe-quota {
//   display: flex;
//   flex-flow: row nowrap;
//   justify-content: flex-start;
//   width: 100%;
//   margin: 0 0 0 32px;
//   text-align: left;
//   position: relative;
//   top: -15px;
// }

// .transcribe-select .transcribe-quota p {
//   margin: 0 2px;
//   font-size: 14px;
//   color: rgba(0,0,0,.56);
// }

// .transcribe-select .transcribe-quota i {
//   margin: 0 2px;
//   font-size: 16px;
// }
// .select-speakers {
//   margin: 24px auto;
//   width: 65%;
// }

// .rec-names {
//   display: flex;
//   flex-flow: column;
//   align-items: center;
//   width: 40%;
//   margin: 0 auto;
//   padding: 12px 0;
// }

// .modal-sub-header {
//   margin-top: 20px;
//   color: rgba(0, 0, 0, .75)
// }

// .modal-header-caption {
//   width: 90%;
//   margin: 24px auto
// }

// .rec-summary-name {
//   display: flex;
//   align-items: center;
//   margin: 8px 12px 8px 0px;
//   color: rgba(0, 0, 0, .33)
// }

// .rec-name {
//   font-size: 18px;
//   color: rgba(0, 0, 0, .75)
// }


</style>
