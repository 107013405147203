<template>
<div class="player-div" @mouseenter="videoMouse('enter')" @mouseleave="videoMouse('leave')">
    <div class="loading-overlay" v-if="!recLoaded">
            <i class="material-icons spin-delay">hourglass_empty</i>
            <p>{{$t('media.loadingMedia')}}</p>
    </div>
    <video id="player" playsinline :class="[mediaType == 'audio' ? 'audioclass' : 'videoclass']" >
    </video>

</div>
</template>

<script>
import Plyr from 'plyr'
import shaka from 'shaka-player'
import 'plyr/dist/plyr.css'
import EventBus from './../../eventBus'
import analytics from './../../analytics'

export default {
    data () {
        return {
            player: {},
            videoDuration: 0,
            pausePlaying: false,
            finishTime: 0,
            videoEl: '',
            shakaInstance: {},
            loaded: false,
        }
    },
    methods: {
        toggleCaptions: function () {
            this.player.toggleCaptions()
        },
        playVideo: async function (startTime, endTime) {

            this.player.currentTime = startTime
            this.finishTime = endTime
            this.pausePlaying = true

            this.player.play()

        },
        loadVideo: function() {

            this.$store.commit('SET_PLYR_STATE', 'loading')

            this.videoEl = document.querySelector('#player');

            this.player = new Plyr(this.videoEl, {
                controls: [],
                hideControls: true,
                storage: {enabled: true},
                captions: { active: true, language: 'auto', update: false },
            })

            if (this.playLocal) {
                this.loadLocal()
            } else {
                this.loadNetwork()
            }

        },
        loadLocal: function () {

            this.player.source = {
                type: this.mediaType,
                title: this.recDetails.Name,
                sources: [
                    {
                        src: this.mediaURL,
                    }
                ],
            }
        },
        loadNetwork: async function () {

            this.$store.dispatch('SET_LOADED', false)

            this.shakaInstance = new shaka.Player(this.videoEl)

            shaka.polyfill.installAll();

            // set the video players requests to send credentials (cloudfront cookies)
            this.shakaInstance.getNetworkingEngine().registerRequestFilter(function(type, request) {
                request.allowCrossSiteCredentials = true;
            })

            try {
            await this.shakaInstance.load(this.mediaURL);

            } catch {
                if (this.hlsURL != "") {
                    await this.shakaInstance.load(this.hlsURL)
                    .catch(async () => {
                        this.shakaInstance.load(this.fallbackURL)
                        .catch(() => { EventBus.$emit("SHOW_MESSAGE", "Unable to load video player")})
                    });
                    } else {
                        await this.shakaInstance.load(this.fallbackURL)
                        .catch(() => {EventBus.$emit("SHOW_MESSAGE", "Unable to load video player")})
                    }
            }

            if (this.captionURL != "") {
                await this.shakaInstance.addTextTrack(this.captionURL, this.recDetails.Captions[0], 'caption', 'text/vtt')
                    .then(track => {
                        this.shakaInstance.selectTextTrack(track);
                        this.shakaInstance.setTextTrackVisibility(true)
                    })
            }
        },
        setMeta: function() {

            var self = this

            this.player.on('loadedmetadata', function () {

                self.videoDuration = self.player.duration
                self.$store.dispatch('SET_DURATION', self.videoDuration)
                self.$store.dispatch('SET_PLYR_STATE', 'ready')
                self.$store.dispatch('SET_LOADED', true)

                self.loadhighlights = true
                self.loaded = true

            })

            EventBus.$on("PLAY_INTERVAL", times => {
                self.playVideo(times[0], times[1])
            })

            EventBus.$on('PLAY_HIGHLIGHT', (highlight) => {
                self.playVideo(highlight.TimeStart, highlight.TimeEnd)
                this.$store.dispatch('SET_HL_PLAYING', highlight)
            })

            EventBus.$on('SET_SPEED', speed => {
                self.player.speed = speed
            })

            EventBus.$on('PLYR_SKIP', (interval) => {
                self.player.currentTime = this.currentTime + interval
            })

            EventBus.$on('PLAY_FROM', (time) => {
                self.playVideo(time)
            })

            EventBus.$on('PLYR_SEEK_TO', (time) => {
                self.player.currentTime = parseFloat(time)
            })

            EventBus.$on('PLYR_TOGGLE', () => {

                if (self.player.playing) {
                    self.player.pause()
                    analytics.gaMedia('pause', this.mediaType)
                } else {
                    self.player.play()
                    analytics.gaMedia('play', this.mediaType)
                }

            })

            EventBus.$on('PLYR_PAUSE', () => {
                self.player.pause()
            })

            EventBus.$on('PLYR_FULLSCREEN', () => {
                self.player.fullscreen.toggle()
            })

            EventBus.$on('PLYR_TOGGLE_CAPTIONS', () => {

                self.player.toggleCaptions()
            })
            this.player.on('timeupdate', function() {
                self.$store.commit('SET_CURRENT_TIME', self.player.currentTime)
            })

            this.player.on('seeked', function() {
                self.$store.commit('SET_CURRENT_TIME', self.player.currentTime)
            })

            this.player.on('seek', function () {
                self.finishTime = 0
                self.pausePlaying = false
                self.$store.dispatch('SET_HL_PLAYING', null)
            })

            this.player.on('pause', function () {
                self.finishTime = 0
                self.pausePlaying = false
                self.$store.dispatch('SET_HL_PLAYING', null)
                self.$store.commit('SET_PLYR_STATE', 'paused')
            })

            this.player.on('play', function () {
                self.$store.commit('SET_PLYR_STATE', 'playing')
            })

            this.player.on('ended', function () {
                self.$store.commit('SET_PLYR_STATE', 'ended')
            })

        },
        videoMouse: function (type) {
            EventBus.$emit('VIDEO_HOVER', type)
        }
    }, 
    mounted () {

        this.loadVideo()
        this.setMeta()

    },
    watch: {
        currentTime: function () {
            if (this.pausePlaying) {

                if (this.currentTime > this.finishTime) {
                    this.player.pause()
                    this.$store.dispatch('SET_HL_PLAYING', null)
                }
            }
        }
    },
    computed: {
        currentTime () {
            return this.$store.getters.GET_CURRENT_TIME
        },
        highlights () {
            return this.$store.getters.HIGHLIGHTS
        },
        // recDetails () {
        //     return this.$store.getters.REC_DETAILS
        // },
        recLoaded () {
            return this.$store.getters.REC_LOADED
        },
        captionURL () {

            return this.recDetails.Captions
            ? `${process.env.VUE_APP_MEDIA_URL}${this.recDetails.UserID}/${this.recDetails.CollectionID}/${this.recDetails.ItemID}/captions/captions-${this.recDetails.Captions[0]}.vtt`
            : ''

        }
    },
        beforeDestroy () {
        EventBus.$off('PLAY_HIGHLIGHT')
        EventBus.$off('PLYR_TOGGLE')
        EventBus.$off('PLAY_FROM')
        EventBus.$off('SET_SPEED')
        EventBus.$off('PLYR_TOGGLE_CAPTIONS')
        EventBus.$off('PLYR_PAUSE')

        this.player.off()
        this.player.off('seeked')
        this.player.destroy()
        this.$store.commit('SET_DURATION', 0)
    },
    props: ["mediaURL", "hlsURL", "fallbackURL", "mediaType", "playLocal", "recDetails"],
    components: {
        // HighlightBar
    }
}
</script>

<style scoped>


#player{
    height: 100%;
    width: 100%;
    overflow: hidden;
}

</style>

<style lang="scss">

.plyr__caption {
    background: rgba(0,0,0,0.7);
    border-radius: 4px;
    color: white;
}

.plyr__caption :past {
    color: pink;
}

/* VIDEO CONTROLS */

#saylient-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#player {
    cursor: pointer;
}


.progress-control {
    display: flex;
    flex-direction: row;
    flex-grow: 2;
    justify-content: center;
}

#player-bar {
    width: 70%;
    flex-grow: 3;
    margin: 0 20px 0 5px;
}


.relative {
  position: relative;
}

#highlight-sider {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
/*
#app .plyr__tooltip {
    margin: 0 0 17px 0;
    padding: 5px 7.5px 8px 7.5px;
    background: rgb(100, 255, 218);
}

#app .plyr__tooltip::before {
    border-color: rgb(100, 255, 218) transparent transparent transparent
}

.plyr--full-ui input[type=range] {
    color: rgb(174, 175, 176);
}

.plyr__progress__buffer{
    opacity: 0;
}

.plyr--video {
    overflow: visible;
}

.fadehighlights {
    opacity: 0.25;
} */


#player.audioclass {
    background:#34B6FC40;
    height: 0;
    overflow: hidden;
}

</style>

<style lang="scss">

#app .plyr__poster {
    cursor: pointer;
}

</style>