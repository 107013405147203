<template>

    <div class="new-word" @click.stop="" @click="startEditing" :class="state" v-tooltip="{ content: 'Add or split' , classes: 'app-tooltip'}">

        <div class="new-word-dot" v-show="state == 'hide'"></div>

        <input type="text" :id="'input_new-word__'+ beforeIndex" class="edit-input" v-show="state=='editing'" v-model="inputText" :style="{width: inputText.length + 'ch'}"  inputmode="search"
            :class="{'invalid' : !validText}"
            @change="newWord"
            @keyup.esc="stopEditing"
        >

         <div class="new-edit-options" v-show="state == 'editing'" :class="{'open' : state == 'editing'}">
            <div class="material-icons" @click="newWord" :class="{'no-save' : !canSave}">check</div>
            <div class="material-icons"  @click="startSplitting" @click.stop="" :class="{'no-save' : beforeIndex +1 >= length}">unfold_more</div>
        </div>

        <div class="row-splitter" :class="{'splitting' : state == 'splitting'}" @click="splitQuote" @click.stop="">
            <div class="material-icons">keyboard_arrow_left</div>
            <div class="splitter-text">{{$t('transcript.editQuoteSplit')}}</div>
            <div class="material-icons">keyboard_arrow_right</div>

        </div>


    </div>

</template>

<script>
import EventBus from './../../eventBus'

export default {
    props: ["beforeIndex", "length"],
    data () {
        return {
            editing : false,
            inputText: '',
            splitting: false,
            state: 'hide'
        }
    },
    computed: {
        validText () {
            return  /^[a-zA-Z0-9!,'.]{0,24}$/.test(this.inputText)
        },
        canSave () {
            return this.validText && this.inputText != ""
        }
    },
    methods: {
        startEditing: function () {

            EventBus.$emit("WORD_SELECTED")

            this.state = "editing"

            const iClass = '#input_new-word__'+ this.beforeIndex

            document.querySelector(iClass).focus()

            setTimeout(function () { document.querySelector(iClass).select(); }, 200)

        },
        startSplitting: function () {

            if (this.beforeIndex +1 >= this.length) {
                return
            }

            this.inputText = ""
            this.state = "splitting"

        },
        splitQuote: function () {

            if (this.state == "splitting") {
            EventBus.$emit("SPLIT_QUOTE", this.beforeIndex)

            }

        },
        stopEditing: function () {
            this.editing = false
        },
        newWord: function () {

            if (!this.canSave) {
                return
            }

            const update = {
                index: this.beforeIndex,
                word: this.inputText
            }

            EventBus.$emit("WORD_NEW", update)

        }

    },
    mounted () {

        const self = this

        EventBus.$on("WORD_SELECTED", () => {
                self.state = "hide"
        })
    }

}
</script>

<style scoped>

.new-word {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 2px 0 2px;
    padding: 2px;
    border: 1px solid #00000000;
    border-radius: 4px;
    height: 80%;
    cursor: pointer;
    width: 10px;
    transition: 1s all;
    position: relative;
    flex-shrink: 0;
}

.new-word.splitting, .no-touch .new-word.splitting:hover{
    width: auto;
    border: 1px solid #00BB5770;
    background: #00BB5720;
}



.no-touch .new-word:hover {
    border: 1px solid #0BAAFF70;
}


.no-touch .new-word:hover .new-word-dot {
    opacity: 0;
}

.editing {
    width: auto;
    min-width: 20px;
    width: auto;
    border: 1px solid #0BAAFF20;
    background: #0BAAFF20
}

.new-word-dot {
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background: #0BAAFF50;
}

.word {
    position: relative;
    margin-right: 4px;
    padding: 2px;
    border: 1px solid #00000010;
    border-radius: 4px;
    margin-top: 5px;
    cursor: pointer;
    display: flex;
}

.selected {
    background: #0BAAFF20;
}

.no-touch .word:hover {
    border: 1px solid #0BAAFF
}

.new-edit-options {
    position: absolute;
    top: 0;
    right: -63px;
    color: white;
    background: #4a4a4a;
    display: flex;
    border-radius:  0 4px 4px 0;
    height: 100%;
    z-index: 10;
    width: 0;
    display: flex;
    align-items: center;
}

.new-edit-options.open {
    width: auto;
}

.new-edit-options .material-icons {
    margin: 5px 8px;
    font-size: 0.9em;
    cursor: pointer;
}

.edit-input {
    width: auto;
    border-radius: 4px;
}

.invalid {
    color: rgba(255, 0, 0, .55);
}

input:focus {
    outline:none;
}

.no-touch .edit-options .material-icons:hover {
    text-shadow: 0 0 10px #ffffff;
}

.word-box {
    font-family: monospace, monospace;
}

 .new-word input, input:before, input:after {
      -webkit-user-select: initial;
      -khtml-user-select: initial;
      -moz-user-select: initial;
      -ms-user-select: initial;
      user-select: initial;
     }

.edit-options {
    position: absolute;
    top: 0;
    right: -30px;
    /* border: 1px solid red; */
    color: white;
    background: #4a4a4a;
    display: flex;
    /* padding: 4px; */
    border-radius:  0 4px 4px 0;
    height: 100%;
    z-index: 10;
    width: 0;
    display: flex;
    align-items: center;
}

.edit-options.open {
    width: auto;
}

.edit-options .material-icons {
    margin: 5px 8px;
    font-size: 0.9em;
    cursor: pointer;
}

.row-splitter {
    width: 0;
    transition: all 1s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
}

.splitting .row-splitter {
    width: 200px;
    height: 100%;
}

.splitter-text {
    flex-grow: 2;
    text-align: center;
    overflow: hidden;
    font-size: 0.7em;
    max-height: 1.5em;
}

.hide .splitter-text  {
    opacity: 0;
    display: none;
    visibility: hidden;
}

.no-save {
    opacity: 0.2;
}


</style>