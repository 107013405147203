<template>
<transition name="modal">
    <div class="modal-mask" @click="$emit('close')">
      <div class="modal-wrapper">
        <div class="modal-container export-transcript--container" @click.stop="">

        <div class="modal-title-bar">
            <h2 class="modal-header">{{$t('modal.exportTranTitle')}}</h2>
            <v-btn icon @click="$emit('close')">
            <div class="material-icons modal-title-close">clear</div>
            </v-btn>
        </div>

        <div class="modal-content">

        <div class="export-transcript--options">
            <v-switch
            v-model="showTimestamp"
            color="#2196f3"
            :label="$t('modal.exportTranTimeLabel')">
            </v-switch>

            <v-switch
            color="#2196f3"
            v-model="showSpeakerNames"
            :label="$t('modal.exportTranSpeakerLabel')">
            </v-switch>

        </div>

        <div>

            <textarea id="transcript-textarea"
            class="export-transcript--content"
            v-model="formattedTranscript"
            rows="50">

            </textarea>

        </div>

        <div>

        </div>

        </div>

        <div class="button-row">
        <v-btn
          @click="$emit('close')"
          >
          {{$t('actions.close')}}
        </v-btn>

        <v-btn
          color="#0BAAFF"
          class="focus-button"
          @click="copyToClipboard"
          >
         {{$t('modal.exportTranCopyAction')}}
        </v-btn>

      </div>

        </div>
    </div>
</div>
</transition>
</template>

<script>
import EventBus from './../../eventBus'
import * as clipboard from 'clipboard-polyfill'
import analytics from './../../analytics'

export default {
    props: ['transcript', 'speakerNames'],
    data () {
        return {
            showTimestamp: false,
            showSpeakerNames: false,
            formattedTranscript: "",
            loadingTranscript: false,
        }
    },
    mounted: function() {

        this.convertTranscript()

    },
    watch: {
        showTimestamp: function() {
            this.convertTranscript()
        },
        showSpeakerNames: function() {
            this.convertTranscript()
        },
    },
    methods: {
        copyToClipboard () {

            clipboard.writeText(this.formattedTranscript)
            .then(() => EventBus.$emit('SHOW_MESSAGE', this.$i18n.t('actions.copySuccess'), 'success'),
            analytics.gaTranscript('export')
            )
            .catch(() =>EventBus.$emit('SHOW_MESSAGE', this.$i18n.t('actions.copyFailed'), 'error'))

        },
        convertTranscript () {

            this.loadingTranscript = true
            let dialogues = this.createDialogues(this.transcript)
            this.formattedTranscript = dialogues.join('\n \n')
            this.loadingTranscript = false
        },
        // extracts the raw text from each chunk of the transcript
        createDialogues (transcript) {

            let newTranscript = transcript.map((chunk) => {

                let dialogue = ""

                if (this.showTimestamp) {
                    dialogue = dialogue + this.$options.filters.timeFormat(chunk.TimeStart)  + " - "
                }

                if (this.showSpeakerNames) {
                    dialogue = dialogue + this.getSpeakers(chunk.Speaker) + ": "
                }

                if (chunk.Dialogue) {
                    chunk.Dialogue.forEach(textEl => {
                        dialogue = dialogue + this.getText(textEl)
                    });
                }

                return dialogue.trim()

            })
            return newTranscript
        },
        getText (textEl) {
        // Check if there is a timestamp on the word - if not, it's punctuation
        return !textEl.T ? textEl.W : " " + textEl.W
        },
        getSpeakers (speaker) {

            let newName = this.$options.filters.speakerize(speaker)

            if (this.speakerNames) {

            this.speakerNames.forEach( speakerName => {
                if (speakerName.Speaker == speaker) {
                    newName = speakerName.Alias
                }

            })

            }

            return newName

        },
    }
}
</script>

<style>

#app .export-transcript--container {
    width: 100%;
    max-width: 900px;
}

.export-transcript--content {
    width: 100%;
    max-height: 300px;
    padding: 1em;
    overflow-y: scroll;
    overflow-x: hidden;
    white-space: pre-wrap;
    text-align: left;
    border: 1px solid #aaaaaa40;
}

</style>