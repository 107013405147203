<template>
<div class="card highlight-card no-highlight"
v-bind:class="{'hl-expanded': show}"
@click="show = !show"
@mouseover="highlightHover()"
@mouseleave="highlightLeave()"
@contextmenu="handler($event)"
>

    <div class="hl-top-content" :class="{'hovered' : highlighted}">
        <div @click.stop="">
            <div icon class="icon-box saylient-btn" v-ripple @click="playHighlight()">
            <i class="material-icons icon">play_arrow</i>
            </div>
        </div>

        <div class="hl-title">
            <div class="title-case no-highlight">{{ highlight.Name }}</div>
        </div>
        <div class="hl-time no-highlight">{{timeStart}} - {{timeEnd}}</div>

        <div icon class="icon-box saylient-btn" @click="show = !show" @click.stop="" v-ripple v-if="!(!highlight.Tags && !highlight.Description)">
            <i class="material-icons icon">{{ show ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</i>
        </div>

        <div @click.stop=""
            class="highlight-option-btn no-highlight saylient-btn material-icons"
            v-ripple
            v-tooltip="{ content: 'Options' , classes: 'app-tooltip'}"
            @click="showOptions = true"
            >
            more_vert
        </div>
    </div>

    <div class="expand bottom-content" v-bind:class="{expanding: show}" v-if="!(!highlight.Tags && !highlight.Description)">

        <div class="description-box" v-show="highlight.Description">
            <p>{{highlight.Description}}</p>
        </div>

        <div class="tag-box" v-show="highlight.Tags" @click.stop="">
            <div outline color="primary" class="hl-tag tag-chip" v-for="tag in highlight.Tags" :key="tag" disabled>{{tag}}</div>
        </div>
    </div>

    <highlight-options v-if="showOptions"
    @close="showOptions = false"
    @playhl="playHighlight()"
    @copyhl="copyToClipboard()"
    @deletehl="deleteHighlight"
    @edithl="startEdit"
     ></highlight-options>

</div>
</template>

<script>
import EventBus from './../../eventBus'
import * as clipboard from 'clipboard-polyfill'
import OptionsMD from './../UI/OptionsMD'
// import LongPress from 'vue-directive-long-press'

export default {
    props: ["highlight", "scrolling", "share"],
    data () {
        return {
            show: false,
            showOptions: false,
        }
    },
    directives: {
    },
    methods: {
        playHighlight: function () {
            EventBus.$emit('PLAY_HIGHLIGHT', this.highlight)
        },
        handler: function(e) {
            if (!this.scrolling){ this.showOptions = true}
            e.preventDefault();
        },
        highlightHover: function () {
            this.$store.dispatch('SET_HL_HOVER', this.highlight)
        },
        highlightLeave: function () {
                this.$store.dispatch('SET_HL_HOVER', {})
        },
        hlLongPressStart: function() {
            if (!this.scrolling){this.showOptions = true}
        },

        copyToClipboard: function () {

        const hlText = `${this.highlight.Name}: ${this.highlight.Description}`

            clipboard.writeText(hlText)
            .then(() => {
                EventBus.$emit("SHOW_MESSAGE", this.$i18n.t('actions.copySuccess'))
            })
            .catch(() => {
                EventBus.$emit("SHOW_MESSAGE", this.$i18n.t('actions.copyFailed'))
            })
        },
        startEdit: function() {
            const highlightDetails = {...this.highlight}
            this.$store.dispatch('SET_HL_HOVER', {})
            this.$store.dispatch('CLOSE_HL_MODAL')
            this.$store.dispatch('SET_EDIT', highlightDetails)
            this.showOptions = false
        },
        deleteHighlight: function () {
            EventBus.$emit("DELETE_HIGHLIGHT", this.highlight)
        },
    },
    computed: {
        timeStart: function () {
            return this.$options.filters.timeFormat(this.highlight.TimeStart)
        },
        timeEnd: function () {
            return this.$options.filters.timeFormat(this.highlight.TimeEnd)
        },
        highlighted: function () {

            if (this.$store.getters.HL_HOVER.HighlightID) {
                return this.$store.getters.HL_HOVER.HighlightID == this.highlight.HighlightID
            } else {
                return false
            }

        },
    },
    components : {
        highlightOptions : OptionsMD
        }
    }
</script>

<style scoped lang="scss">
@import '@/styles/abstracts/_variables.scss';
@import '@/styles/components/_cards.scss';

.hl-expanded {
    /* border-radius: 8px;   */
    max-height: 350px;
}


</style>