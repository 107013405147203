<template>
      <transition name="modal">
    <div class="modal-mask no-highlight"
        @click="$emit('close')"
        >
      <div class="modal-wrapper">
        <div class="modal-container"
        @click.stop="">
 
        <div class="modal-title-bar">
            <h2 class="modal-header">{{title}}</h2>
            <v-btn flat icon @click="$emit('close')">
            <div class="material-icons modal-title-close">clear</div>
            </v-btn>
        </div>

      <div class="modal-content">

            <h3>{{msg}}</h3>

        </div>

        <div class="button-row">
            <v-btn 
            @click="$emit('close')"
            >
            {{$t('actions.cancel')}}
            </v-btn>

            <v-btn 
            color="success"
            @click="$emit('confirm')"
            >
            {{$t('actions.ok')}}
            </v-btn>

      </div>

        <div>
        </div>
    </div>
    </div>
    </div>
    </transition>
</template>

<script>
export default {
    props: ["msg", "title"],
    data () {
        return {

        }
    },
    mounted () {

    }, 
    methods: {

    }
}
</script>

<style scoped>
/* 
.modal-mask {
    z-index: 999;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #ffffff99;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}


.modal-container {
    z-index: 11;
    width: 90%;
    max-width: 500px;
    background-color: white;
    margin: 0 2em;
    display: flex;
    flex-flow: column;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 3px 3px 10px #00000050, 0px 0px 100px #00000050;  
    padding: 0;
    height: auto;
}

.modal-content {
    width: 90%;
    padding: 2em 1em;
    height: 100%;
    overflow: hidden;
}

.modal-options {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
} */


</style>

