<template>
      <transition name="modal">
    <div class="modal-mask"
        @click="$emit('close')"
        >
      <div class="modal-wrapper">
        <div class="modal-container"
        @click.stop="">

    <div class="modal-title-bar">
            <h2 class="modal-header">{{$t('modal.deleteRecordingTitle')}}</h2>
            <v-btn flat icon @click="$emit('close')">
            <div class="material-icons modal-title-close">clear</div>
        </v-btn>
    </div>

    <div class="modal-content">
    <h3 class="modal-sub-header">{{$t('modal.deleteRecordingText')}}</h3>
    </div>
    <div class="button-row">
            <v-btn
            @click="$emit('close')"
            >
            {{$t('actions.close')}}
            </v-btn>

            <v-btn
            class="focus-button"
            color="blue"
            light
            @click="deleteRecording"
            >{{$t('actions.delete')}}</v-btn>

    </div>
          </div>
        </div>
      </div>
  </transition>
</template>

<script>
import EventBus from './../../eventBus'

export default {
  methods : {
    deleteRecording: function () {

    const params = {
      collID: this.recDetails.CollectionID,
      recID: this.recDetails.ItemID
    }

    this.$store.dispatch('DELETE_CURR_RECORDING', params)
    EventBus.$emit('SHOW_MESSAGE', this.$t('modal.deleteRecordingSuccess', {name: this.recDetails.Name}))
    this.$router.back()
    this.$emit('close')
    },
  },
  computed :{
    recDetails () {
      return this.$store.getters.REC_DETAILS
    }
  }
}

</script>
