<template>
<transition name="modal">
<div class="modal-mask">
    <div class="modal-wrapper">
        <div class="modal-container" @click.stop="">
            <div class="modal-title-bar">
                <h2 class="modal-header">{{$t('modal.editShareTitle')}}</h2>
                <v-btn icon @click="close">
                    <div class="material-icons modal-title-close">clear</div>
                </v-btn>
            </div>
            <div class="modal-content">
                <transition name="slide-in" mode="out-in">
                <div v-if="!submitted" class="modal-layout">
                    <div class="share-details">
                        <div class="share-link"
                        @click="openLink">
                            <div class="material-icons">link</div>
                            <div class="share-link-txt">{{shareLink}}</div>
                        <v-btn icon @click.stop=""
                        class="copy-btn"
                        v-tooltip="{ content: 'Copy to Clipboard' , classes: 'app-tooltip'}"
                        @click="copyToClipboard">
                        <v-icon class="material-icons">file_copy</v-icon>
                        </v-btn>
                        </div>
                    </div>

                    <div v-if="recDetails.Protected" class="share-detail">
                        <div class="material-icons">lock</div>
                        <div class="share-text">{{$t('modal.editShareStatusPwd')}}</div>
                    </div>

                    <div v-if="!recDetails.Protected" class="share-detail">
                        <div class="material-icons">public</div>
                        <div class="share-text">{{$t('modal.editShareStatusPub')}}</div>
                    </div>

                    <div v-if="recDetails.Expire" class="share-detail">
                        <div class="material-icons">schedule</div>
                        <div class="share-text">{{$t('modal.editShareExpires')}}: {{expirationTime}}</div>
                    </div>
                </div>
                </transition>

                <transition name="slide-in" mode="out-in">
                <div v-if="submitted">
                    <h3>{{$t('modal.editShareRemoved')}}</h3>
                </div>
                </transition>


        </div>

        <div class="button-row">
            <v-btn @click="close">
            {{$t('actions.cancel')}}
            </v-btn>

            <v-btn
            @click="removeSharing"
            class="focus-button"
            color="red"
            v-if="!submitted"
            >{{$t('modal.editShareStopAction')}}</v-btn>

    </div>
    </div>
</div>
</div>
</transition>
</template>

<script>
import requests from './../../requests'
import EventBus from './../../eventBus'
import * as clipboard from 'clipboard-polyfill'


export default {
    props: ["recDetails"],
    data () {
        return {
            submitted: false,
        }
    },
    computed : {
        shareLink () {
            return process.env.VUE_APP_SHARE_URL + this.recDetails.ShareID
        },
        expirationTime () {
            return new Date(this.recDetails.ValidUntil * 1000)
        }
    },
    methods: {
        removeSharing: function () {
            requests.removeSharing(this.recDetails.CollectionID, this.recDetails.ItemID)
            .then(() => {
                this.submitted = true
            })
            .catch(err => {
                EventBus.$emit('SHOW_MESSAGE', err, 'error')
            })
        },
        openLink: function () {

            window.open(this.shareLink)

        },
        copyToClipboard: function () {

            clipboard.writeText(this.shareLink)
            .then(() => {
                EventBus.$emit('SHOW_MESSAGE', this.$i18n.t('actions.copySuccess'), 'success')
                })
            .catch(() => {
                EventBus.$emit('SHOW_MESSAGE', this.$i18n.t('actions.copyFailed'), 'error')
            })

        },
        close: function () {
            if (this.submitted) {
                EventBus.$emit('UPDATE_CURR_REC')
            }

            this.$emit('close')

        },
    },
    watch: {

    }
}
</script>

<style scoped>


.modal-content {
    overflow: hidden;
}

.modal-layout {
    display: flex;
    flex-flow: column;
    align-items: center;
}

.share-details > * {
    margin: 0 0.5em;
}

.share-link {
    display: flex;
    justify-content: center;
    border: 2px solid #4a4a4a;
    border-radius: 4px;
    padding: 0.6em 2em 0.6em 1em;
    align-items: center;
    position: relative;
    cursor: pointer;
    margin: 1em auto;
    /* margin-bottom: 1em; */
    width: 100%;
}

.share-link-txt {
    width: 100%;
    flex-flow: 3;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    height: 0px;
    background: transparent;  /* Optional: just make scrollbar invisible */
}

.share-link-txt ::-webkit-scrollbar {
    display: none;
}

.copy-btn {
    position: absolute;
    right: -3.5em;
    top: 0;
    opacity: 0.4;
}

.share-link > * {
    margin: 0 10px;
    font-weight: 700;
}

.share-detail {
    display: flex;
    align-items: flex-start;
    margin: 1em;
    width: 90%;
}

.share-details {
    margin-bottom: 3em;
    max-width: 80%;

    /* overflow:hidden; */
}

.share-text {
    text-align: left;
    margin-left: 1em;
    font-weight: 700;
}

</style>
