<template>
    <div class="bar-container" v-if="videoDuration">

      <div v-for="highlight in highlights" :key="highlight.HighlightID" class="highlight">

        <div class="highlight-bar"
        :class="(highlight.HighlightID == highlightedID ? 'hovered': '' )"
        v-bind:style="{left: (highlight.TimeStart / videoDuration * 100) + '%', 
        width: (highlight.TimeEnd - highlight.TimeStart)/videoDuration * 100 + '%'}"
        @click="playHighlight(highlight)" 
        @mouseover="highlightHover(highlight)"
        @mouseleave="highlightLeave()"
        >
        <div class="highlight-label" 
        :class="{'hidden' : highlightedID != highlight.HighlightID}"
        >{{highlight.Name}}</div>

        </div>
        </div>
        
        <div class="highlight-bar slider-highlight" v-if="editingHighlight"
        v-bind:style="{left: (sliderMin / videoDuration * 100) + '%', 
        width: (sliderMax - sliderMin)/videoDuration * 100 + '%'}"
        >
        </div>


    </div>
</template>


<script>
// v-show="highlightedID == highlight.HighlightID"
import EventBus from './../../eventBus'

export default {
    computed: {
        highlightedID () {
            return this.$store.getters.HL_HOVER.HighlightID
        },
        videoDuration () {
          return this.$store.getters.GET_DURATION
        },
        highlights () {
          return this.$store.getters.HIGHLIGHTS
        },
        sliderMin () {
          return this.$store.getters.GET_MIN
        },
        sliderMax () {
          return this.$store.getters.GET_MAX
        },
        editingHighlight () {
          return this.$store.getters.MODAL
        }
    },
    methods: {
      playHighlight: function (highlight) {
        EventBus.$emit('PLAY_HIGHLIGHT', highlight)
      },
      highlightHover: function (highlight) {
        this.$store.dispatch('SET_HL_HOVER', highlight)
      },
      highlightLeave: function () {
        this.$store.dispatch('SET_HL_HOVER', {})
      }

    }
}
</script>

<style scoped>

 .bar-container {
    /* pointer-events: none; */
    margin: 0 auto;
    /* position: absolute; */
    z-index: 3;
    bottom: 0;
    height: 100%
} 

.highlight-bar {
    pointer-events: auto;
    top: 0%;
    margin: 0;
    position: absolute;
    /* height: 8px; */
    height:100%;
    /* background-color: rgb(100, 255, 218); */
    background-color: #0BAAFF;
    box-sizing: border-box;
    opacity: 0.5;
    border-radius: 2px;
    z-index: 3;
}

.slider-highlight {
  opacity: 0.8;
  pointer-events: none;
}

.highlight-bar:hover, .hovered {
    box-shadow: 0 0 6px;
    opacity: 1;
    cursor: pointer;
    border-radius: 0 0 2px 2px;
}

.hidden.highlight-label {
  top: -15px;
  opacity: 0;
  pointer-events: none;
}

.no-touch .highlight-label:hover {
  box-shadow: 0 0 6px;
}

.highlight-label {
  position: absolute;
  top: -30px;
  font-size: 0.8em;
  text-align: center;
  background-color: #4a4a4a90;
  /* border: 1px solid rgba(100, 255, 218); */
  border: 2px solid #0BAAFF;
  padding: 4px 8px;
  border-radius: 4px 4px 4px 0px ;
  font-weight: 700;
  white-space: nowrap;
  color: white;
  opacity: 1;
  transition: all 0.2s;
  pointer-events: auto;
}

</style>

<style>
/* 
.highlight-tooltip {
  display: block !important;
  z-index: 10000;
}

.highlight-tooltip {
    background: rgb(100, 255, 218);
    border-radius: 8px;
    padding: 5px 10px 4px;
    border-width: 2px;
    border-color: rgb(100, 255, 218);
    
    box-shadow: 0 0 6px white;
    color: #4a4a4a;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 700;
}

.highlight-tooltip .tooltip-icon {
  font-size: 16px; 
  margin-right: 3px;
}

.highlight-tooltip .tooltip-text {
  margin: 0 auto;
  display: inline;
}

.highlight-tooltip .tooltip-content {
  display: flex;
  align-items: center;
}

 .highlight-tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: rgb(100, 255, 218);
  z-index: 1;
}

.highlight-tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.highlight-tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
} */
/* 
.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, .1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
} */

 /* .highlight-tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .0.5s, visibility 0.5s;
}

 .highlight-tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s;
} */
</style>




