<template>

    <div class="word no-highlight" @click.stop="" :class="{'selected' : selected}">
        <div class="word-box"  @click="updateSelection" v-if="!editing">
            {{word.W}}
        </div>

        <input type="text"  class="edit-input" :id="'input_word__'+ index" v-show="editing" v-model="inputText" :style="{width: inputText.length + 'ch'}" inputmode="search" 
            @keyup.enter="finishEditing"
            @change="finishEditing"
            @keyup.esc="updateSelection"
            :class="{'invalid' : !validText}"
        >
        <div class="edit-options" v-if="editing" :class="{'open' : selected}" @click="deleteWord" >
            <div class="material-icons" >clear</div>
        </div>

    </div>
</template>

<script>
import EventBus from './../../eventBus'

export default {
    props: ["word", "index"],
    data () {
        return {
            selected: false,
            editing: false,
            inputText: ''
        }
    },
    computed: {
        validText () {
            return  /^[a-zA-Z0-9!,'.]{0,24}$/.test(this.inputText)
        }
    },
    methods: {
        updateSelection: function () {

            EventBus.$emit("WORD_SELECTED", this.index)
            this.selected = !this.selected
            this.editing = !this.editing

            // this.$el.querySelector(iClass)

            const iClass = '#input_word__'+ this.index

            document.querySelector(iClass).focus()
            // document.querySelector(iClass).select()

            setTimeout(function () { document.querySelector(iClass).select(); }, 200)
        },
        finishEditing: function () {

            if (!this.validText) {
                return
            }

            if (this.inputText == "") {
                this.deleteWord()
                return
            }

            this.selected = false
            this.editing = false

            const newWord = {
                W: this.inputText
            }

            if (this.word.T) {
                newWord.T = this.word.T
            }

            const update = {
                index: this.index,
                word: newWord
            }

            EventBus.$emit("WORD_EDIT", update)

        },
        deleteWord: function () {

            EventBus.$emit("WORD_DELETE", this.index)
            this.selected = false
            this.editing = false

        }
    },
    mounted () {

        this.inputText = this.word.W

        const self = this

        EventBus.$on("WORD_SELECTED", index => {
            if (index != this.index) {
                self.selected = false
                self.editing = false
            }
        })

        EventBus.$on("WORD_UPDATED", update => {
            if (update.index == this.index) {
                self.inputText = update.word.W
                self.word.W = update.word.W
            }
        })
    }
    
}
</script>

<style scoped>

.word {
    position: relative;
    /* margin-right: 4px; */
    padding: 2px;
    border: 1px solid #00000000;
    border-radius: 4px;
    margin-top: 5px;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    /* font-family: monospace, monospace; */
    /* font-weight: 700; */
}

.selected {
    background: #0BAAFF20
}

.no-touch .word:hover {
    border: 1px solid #0BAAFF
}

.edit-options {
    position: absolute;
    top: 0;
    right: -30px;
    /* border: 1px solid red; */
    color: white;
    background: #4a4a4a;
    display: flex;
    /* padding: 4px; */
    border-radius:  0 4px 4px 0;
    height: 100%;
    z-index: 10;
    width: 0;
    display: flex;
    align-items: center;
}

.edit-options.open {
    width: auto;
}

.edit-options .material-icons {
    margin: 5px 8px;
    font-size: 0.9em;
    cursor: pointer;
}

.edit-input {
    width: auto;
    /* border: 1px solid #4a4a4a; */
    border-radius: 4px;
    /* font-family: monospace, monospace; */
}

input {
    /* -webkit-appearance: none; */
    z-index: 10;
    /* border: 1px solid red; */
    box-sizing: border-box;
    /* background: #4a4a4a20; */
}

.invalid {
    color: rgba(255, 0, 0, .55);
}

input:focus {
    outline:none;
}

.word input, input:before, input:after {
      -webkit-user-select: initial;
      -khtml-user-select: initial;
      -moz-user-select: initial;
      -ms-user-select: initial;
      user-select: initial;
     } 

.no-touch .edit-options .material-icons:hover {
    text-shadow: 0 0 10px #ffffff;
}

.word-box.word {
    /* margin-right: 4px; */
    /* border: 1px solid red; */
}

.word-box.punctuation {
    /* margin-left: -4px; */
}

</style>