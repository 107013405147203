<template>

    <transition name="range-fade" appear>
        <div id="slider-box">
                <v-range-slider
                v-model="slideValues"
                :color="'#0BAAFF'"
                :max="videoDuration"
                track-color="transparent"
                thumb-label="always"
                :thumb-size="tSize"
                ></v-range-slider>
        </div>
    </transition>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
    data () {
        return {
            tSize: 25
        // rangeFormat: {color: 'green-accent-2' }
            }
        },
    computed: {
    ...mapGetters({
      videoDuration : 'GET_DURATION',
      sliderMin: 'GET_MIN',
      sliderMax: 'GET_MAX',
      mobile: 'IS_MOBILE',
      windowWidth: 'WIDTH'
        }),
     slideValues: {
        get () {
            return [this.sliderMin, this.sliderMax]
        },
        set (values) {
            this.$store.dispatch('SET_SLIDER_RANGE', values)
        }
    }
    },
    mounted () {

        if (this.sliderMax > this.videoDuration) {
            this.$store.dispatch('SET_SLIDER_RANGE', [this.sliderMin, this.videoDuration/2])
        }

        if (this.mobile || this.windowWidth < 1000) {
            this.tSize = 40
        }


    }
  }


</script>

<style>

#slider-box {
    z-index: 2;
    padding: 0;
    height: 1px;
    position: absolute;
    width: 100%;
    bottom: 20px;
    touch-action: none;
    margin: 0 auto;
}

#slider-box > div {
    pointer-events: auto;
    padding: 0;
    margin: 0 0 -70px 0;
    box-sizing: border-box;
    height: 20spx;

}
#slider-box .v-slider__thumb {
    width: 2px;
    height: 0px;
    left: -2px;
    touch-action: none;
    box-shadow: 0 0 6px;
}

#slider-box {
    margin-bottom: 5px;
}

#slider-box .v-slider__thumb-label {
    bottom: -27px;
}

#slider-box * {
    color: rgba(0,0,0,0);
    height: 1px;
}

#slider-box .v-input__control, .v-slot, .v-slider {
    height: 1px;
    /* border: 1px solid pink; */
}

#slider-box .v-slider__track-fill {
    opacity: 0;
    pointer-events: none;
}

#slider-box .theme--light.v-input--slider .v-slider__track,
#slider-box .theme--light.v-input--slider .v-slider__track-fill {
    background: rgba(0,0,0,0);
    pointer-events: none;
}


</style>
