<template>
<div class="overlay">

    <div class="overlay-content">
    <div class="overlay-icon material-icons">{{icon}}</div>

    <div class="overlay-message">
        {{message}}
    </div>
    </div>

</div>
</template>

<script>
export default {
    props: ["icon", "message"]
}
</script>

<style scoped>

.overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: #ffffff20;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
}

.overlay-icon {
    font-size: 3em;
    opacity: 0.8;
    color: #4a4a4a;

}

.overlay-message {
    opacity: 0.8;
    font-weight: 700;
    font-size: 1.5em;
    color: #4a4a4a;

}

</style>