<template>
    <div class="quotecard no-highlight" @mouseover="showHoverMenu = true" @mouseleave="showHoverMenu = false"
    :class="cardClass"
    :id="cardID"
    v-observe-visibility="visibilityChanged"
    @contextmenu="handler($event)"
    >

    <div class="is-playing-bar" :class="isPlaying ? '' : 'not-playing-bar'">
        <div v-if="isPlaying" class="playing-progress" :style="{'height' : progress + '%'}"></div>
    </div>

    <div class="quote-speaker" @click="playHighlight(quote.TimeStart)">
        <div :class="quote.Speaker" class="quote-speaker-bubble"></div>
        <p class="quotetime" v-if="quote.TimeStart">{{quote.TimeStart | timeFormat}}</p>
        <p class="quotetime" v-else>{{0 | timeFormat}}</p>
        <p class="speaker" v-if="quote.DisplayName">{{quote.DisplayName}}</p>
        <p class="speaker" v-else>{{quote.Speaker | speakerize}}</p>
        <i class="material-icons transcript-play">play_arrow</i>
    </div>

    <div class="tombstones" v-show="!visible">
        <div v-for="(tombstone, index) in tombstones" :key="index" class="tombstone"></div>
    </div>

    <div class="words" v-show="visible"
    v-for="(word, index) in quote.Dialogue" :key="index">

    <div @click="playHighlight(word.T)" v-if="isPlaying" :class="isPlayingWord(word.T)">
        <div :class="isHighlighted(word.W)">
            <div class='word-text' :class="getClass(word.W)">{{word.W}}</div>
        </div>
    </div>

    <div @click="playHighlight(word.T)" v-else>
        <div :class="isHighlighted(word.W)">
            <div class='word-text' :class="getClass(word.W)">{{word.W}}</div>
        </div>
    </div>

    </div>

    <div class="hover-background" :class="(showHoverMenu ? 'card-hovered' : '')">
            <div class="hover-menu" v-if="showHoverMenu">
        <div class="hover-menu-buttons">
            <v-btn icon small @click="playHighlight(quote.TimeStart)" v-tooltip="{ content: 'Play' , classes: 'app-tooltip'}"><div class="material-icons">play_arrow</div></v-btn>
            <v-btn icon small @click="showEditQuote = true" v-tooltip="{ content: 'Edit' , classes: 'app-tooltip'}"><div class="material-icons">edit</div></v-btn>
            <v-btn icon small @click="copyToClipboard" v-tooltip="{ content: 'Copy' , classes: 'app-tooltip'}"><div class="material-icons">content_copy</div></v-btn>
            <v-btn icon small @click="flagQuote" v-tooltip="{ content: $t('transcript.flag') , classes: 'app-tooltip'}"><div class="material-icons" v-bind:class="{'has-flags' : hasFlags}">{{cardFlags.length > 0 ? 'bookmark' : 'bookmark_outline'}}</div></v-btn>
        </div>

    </div>
    </div>

    <div v-if="hasFlags && !showHoverMenu" class="has-flag-icon"><div class="material-icons">bookmark</div></div>

    <options v-if="showOptions"
    :tOption="quote"
    :type="'transcript'"
    @close="showOptions = false"
    @playtran="playHighlight(quote.TimeStart)"
    @copytran="copyToClipboard()"
    @showedittran="showEditQuote = true"
    @makehighlight="startHighlight"
    ></options>

    <edit-quote v-if="showEditQuote"
    :quote="quote"
    :index="index"
    @close="showEditQuote = false"
    ></edit-quote>

</div>
</template>

<script>
import { mapGetters } from 'vuex'
import EventBus from './../../eventBus'
import * as clipboard from 'clipboard-polyfill'
import analytics from './../../analytics'
import OptionsMD from './../UI/OptionsMD'
import EditQuoteMD from './EditQuoteMD'
import { ObserveVisibility } from 'vue-observe-visibility'
import requests from './../../requests'

export default {
    props: ["textSearch", "quote", "index", "showFlags"],
    directives : {
        ObserveVisibility
    },
    data () {
        return {
            startTime: 0,
            finishTime: 0,
            showOptions: false,
            showEditQuote: false,
            cardUpdated: false,
            visible: true,
            tombstones: 0,
            avgLine: 30,
            showHoverMenu: false,
            cardFlags: []
        }
    },
    computed : {
    ...mapGetters({
            currentTime: 'GET_CURRENT_TIME',
            speakerNames: 'SPEAKER_NAMES'
        }),
        searchTerms () {
            return this.textSearch.length ? this.textSearch.toLowerCase().trim().split(" ") : []
        },
        hideFlagFilter () {
            return this.showFlags && !this.hasFlags ? true : false
        },
        hide () {

            if (this.textSearch == "" && !this.hideFlagFilter) {
                return false
            }

            if (this.quote.hide || this.hideFlagFilter) {
                return true
            }

            return false

        },
        isPlaying () {
            return this.currentTime >= this.startTime && this.currentTime <= this.finishTime + 1
        },
        cardID () {
            return 'transcript-card-' + this.index
        },
        cardClass () {

            if (this.hide) {
                return 'hidden'
            }

            if (this.cardUpdated) {
                return 'updated'
            }

            if (this.isPlaying) {
                return 'playing'
            }
            return ''
        },
        progress () {

            if (this.startTime > this.currentTime) {
                return 0
            }

            if (this.finishTime < this.currentTime) {
                return 100
            }

            return (this.startTime - this.currentTime) /( this.startTime - this.finishTime) * 100

        },
        hasFlags() {
            return this.cardFlags.length > 0 ? true : false
        },
        recDetails () {
            return this.$store.getters.REC_DETAILS
        },
        flags () {
            return this.$store.getters.REC_FLAGS
        }
    },
    methods : {
        isPlayingWord(time) {
            // highlights the word if it within 0.6 a second of the current playing time
            return time > this.currentTime - 0.6 && time < this.currentTime + 0.6  ?  'is-playing' : ''
        },
        isHighlighted(word) {

            if (this.searchTerms.length < 1) {
                return
            } else {
            //Highlight the word if it appears in any of the search terms
            return this.searchTerms.some(x => word.toLowerCase().includes(x.toLowerCase()) ) ? 'is-searched' : ''
            }
        },
        getClass(word) {
            // learn regex.
            return word === '?' || word === '.' || word === "'" || word === "," || word === `"` ?  'punctuation' : 'word'
        },
        startHighlight: function () {

            const times = [this.startTime, this.finishTime]

            if (times.length == 2) {
                this.$store.dispatch('SET_SLIDER_RANGE', times )
                this.$store.dispatch('NEW_HL_MODAL')
            }
        },
        setHighlightTimes: function () {

            let times = []

            let words = this.quote.Dialogue.filter((word) => {
                return word.T
            })

            let lastEl = words.pop()

            this.startTime = times[0] = Math.floor(parseInt(this.quote.TimeStart), 1)
            this.finishTime = times[1] = Math.ceil(parseInt(lastEl.T), 1)

        },
        copyToClipboard: function () {

            clipboard.writeText(this.makeDialogueCopy(this.quote))
                .then(() => EventBus.$emit('SHOW_MESSAGE', this.$t('actions.copySuccess'), 'success'),
                analytics.gaTranscript('exportQuote')
                )
                .catch(() => EventBus.$emit('SHOW_MESSAGE', this.$t('actions.copyFailed'), 'error'))

        },
        visibilityChanged: function (isVisible) {

            this.visible = isVisible

        },
        makeDialogueCopy: function (quote) {

            let dialogue = ""

                if (quote.Dialogue) {
                    quote.Dialogue.forEach(textEl => {
                        dialogue = dialogue + this.getText(textEl)
                    });
                }

                let name = quote.DisplayName ? quote.DisplayName : this.$options.filters.speakerize(quote.Speaker)

                return this.$options.filters.timeFormat(quote.TimeStart)
                + " - "
                + name
                + ": \n"
                + dialogue.trim()
        },
        getText (textEl) {
            // Check if there is a timestamp on the word - if not, it's punctuation
            return !textEl.T ? textEl.W : " " + textEl.W
        },
        playHighlight: function (time) {
            EventBus.$emit('PLAY_FROM', parseInt(time))
        },
        startTQuoteOptions: function () {
            this.showOptions = true
        },
        handler: function(e) {
            this.showOptions = true
            e.preventDefault();
        },
        calcFlags: function () {

            this.cardFlags = []

            if (!this.flags) {
                return
            }

            for (var i = 0, len = this.flags.length; i < len; i++) {

                if (this.flags[i].Time >= this.startTime && this.flags[i].Time < this.finishTime) {
                    this.cardFlags.push(this.flags[i])
                }
            }

        },
        removeFlag: async function () {

            const response = await requests.deleteRecordingFlag(this.recDetails.CollectionID, this.recDetails.ItemID, this.cardFlags[0])
                if (response.status == 200) {
                    this.$store.dispatch('UPDATE_REC_FLAGS', response.data.data)

                    setTimeout(() => {
                        this.calcFlags()
                        this.cardUpdate()
                    }, 100)

                } else {
                    EventBus.$emit('SHOW_MESSAGE', 'Could not delete flag', 'error')
                }

        },
        flagQuote: async function () {

            if (this.cardFlags.length > 0) {
                this.removeFlag()
                return
            }

            const newFlag = {
                Time: this.startTime
            }

            if (newFlag.Time < 0) {
                return
            }

            const response = await requests.updateRecordingFlags(this.recDetails.CollectionID, this.recDetails.ItemID, newFlag)
            if (response.status == 200) {
                this.$store.dispatch('UPDATE_REC_FLAGS', response.data.data)

                    setTimeout(() => {
                        this.calcFlags()
                        this.cardUpdate()
                    }, 100)

            } else {
                EventBus.$emit('SHOW_MESSAGE', 'Could not update flag', 'error')
            }

            return

        },
        cardUpdate: function() {

            let self = this
            self.cardUpdated = true

            setTimeout(function() {
                self.cardUpdated = false
            }, 400)

        }
    },
    mounted () {
        this.setHighlightTimes()
        this.tombstones = Math.ceil(this.quote.Dialogue.length / this.avgLine)
        this.calcFlags()
    },
    components :{
        options : OptionsMD,
        editQuote: EditQuoteMD
    },
    watch: {
        quote : function() {
            this.cardUpdate()
        },
        progress: function () {
            if (this.progress > 1 && this.progress < 10 && this.isPlaying) {
                EventBus.$emit("SCROLL_TRANSCRIPT", this.cardID)
            }
        }

    }
}
</script>

<style scoped lang="scss">
@import '@/styles/abstracts/_variables';

.quote--options {
    position: absolute;
    top: 0.7em;
    right: 0.4em;
    cursor: pointer;
    display: flex;
}

.is-playing-bar {
    position: absolute;
    left: 0em;
    top: 1em;
    width: 4px;
    background: #d8d8d8;
    height: 105%;
    border-radius: 8px;
    // border: 1px solid red;
    // overflow: hidden;
    transition: 0.4s all;
    // opacity: 1;
}
.not-playing-bar {
    width: 1px;
    background: #cccccc;
    // opacity: 0;
}

.playing-progress {
    background: $accent-color;
    transition: 1s all;
    width: 4px;
    position: absolute;
    top:0;
    left:0;

}

.no-touch .quote--options--item:hover {
    color: rgba(0,0,0,.77);
}

.quote--options--item {
    color: rgba(0,0,0,.38);
    font-size: 1.5em;
    margin: 0 4px;
}

.no-touch .quote--options--item {
    opacity: 0;
}

.no-touch .quotecard:hover .quote--options--item {
    opacity: 1;
}

 .quotecard {
    text-align: left;
    padding: 10px 15px 15px 30px;
    border: 1px solid #00000000;
    border-radius: 0px 4px 4px 0px;
    display: flex;
    flex-flow: row wrap;
    position: relative;
    transition-property: color, 'max-height', 'background', 'text-shadow';
    transition-duration: .3s;
    transition-timing-function: linear;
    max-height: 9000px;
    transition: all 0.4s;
    box-sizing: border-box;
 }

.no-touch .quotecard:hover {
    z-index: 10;
 }

 .hover-menu {
    position: absolute;
    top: 5px;
    height: 2em;
    width: 100%;
    left: 0;
 }

 .hover-background {
    box-shadow: $modal-shadow;
    z-index: -1;
    position: absolute;
    //  background: white;
    bottom: 0;
    width: 100%;
    height: 100%;
    left: 0;
    border-radius: 4px;
    transition: all 0.2s;
 }

 .hover-background.card-hovered {
    height: calc(100% + 1em);
    background: white;
    // z-index: 998;
 }

 .hover-menu {
     display: flex;
     flex-flow: row;
     justify-content: flex-end;
     margin-bottom: 12px;
 }

 .hover-menu-buttons {
    // border: 1px solid red;
     margin: 0 1em;
 }

 .hover-menu-buttons > * {
     margin: 0 0.3em
 }

 .has-flag-icon {
     position: absolute;
     top: 0.2em;
     right: 1.3em;
     color: #ccc;
 }

.quotecard.hidden {
    max-height: 0px;
     padding: 0px 15px;
    // background: white;
    color: transparent;
    overflow: hidden;
    margin: 0 auto;
    display: none;
    border-bottom: 1px solid grey;
    box-sizing: border-box;
}

.quotecard.hidden * {
    color: transparent ;
    background: transparent;
    text-shadow: none;
}

 .spk_0, .spk_3 ,.spk_9 {
    background-color: rgb(180,180,180);
}

.spk_1, .spk_4 ,.spk_7 {
    background-color: #0BAAFF;
}

.spk_2, .spk_5, .spk_8 {
    background-color: #4caf50;
}

.no-touch .quote-speaker:hover {
    color: #0BAAFF;
    cursor: pointer;
 }

 .quote-side-bar {
    width: 6px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-radius: 0;
 }

.quote-speaker-bubble {
     width: 8px;
     height: 8px;
     margin: 0 2px 0 0;
     border-radius: 100%;
 }

.speaker {
    font-size: 12px;
    font-weight: bold;
    display: inline;
    margin: 0 5px;
}

.quote-speaker {
    display: flex;
    width: 100%;
    align-items: center;
    margin: 0;
}
.transcript-play {
    font-size: 14px;
    opacity: 0;
    margin-left: -3px;
}

.no-touch  .quote-speaker:hover .transcript-play{
    opacity: 1;
}

.quotetime {
    font-size: 12px;
    color: rgb(165, 165, 165);
    margin: 2px;
}
.words {
    display: table;
    color:rgb(106, 106, 106);

}

.word-text {
    display: inline;
    touch-action: none;
}

.punctuation {
    position: relative;
    padding: 0px;
    width: 0px;
    margin-left: -2px;
    margin-right: 2px;
}

.word{
    margin: 2px;
}

.no-touch .word:hover {
    color: #0BAAFF;
    cursor: pointer;
}

.is-playing {
    transition: all 0.8s ease-out;
    color: black;
    text-shadow: 0px 0px 10px rgb(100, 255, 218)
}

.is-searched {
    transition: all 0.3s ease-out;
    font-weight: bold;
    text-shadow: 0px 0px 10px rgb(100, 255, 218)
}

.updated {
    // border: 2px solid #00BB57;
   // background: #00BB5710;
    background:  $accent-light;

    .hover-background {
    // border: 2px solid #00BB57;
    background: $accent-light;
    }

}

.tombstones {
    display: flex;
    flex-flow: column;
    width: 100%;
}

.tombstone {
    height: 1.5em;
    width: 100%;
    background-color: #4a4a4a20;
    border-radius: 4px;
    margin: 4px 0;
    align-items: flex-start;
}

.flag {
    width: 12px;
    height: 12px;
    background: $accent-color;
    border-radius: 100%;
    position: absolute;
    // top:0;
    z-index: 15;
}

#app .has-flags {
    color: $accent-color;
}

</style>
