<template>
<div id="highlight-box">

    <div class="tag-filter" v-if="tagList.length > 0">

        <div class="material-icons tag-icon">local_offers</div>

        <div class="tag-chip saylient-btn"
        v-ripple 
        v-for="(tag, index) in tagList" :key="index"
        v-bind:class="(filterTags.includes(tag)? 'active': '')"
        @click="updateFilter(tag)"
        >
            {{tag}}
        </div>
        
    </div>
        
    <div>
        <transition-group name="list" tag="div">
            <highlight v-for="highlight in filteredHighlights"
            :key="highlight.HighlightID"
            :highlight="highlight"
            ></highlight>
        </transition-group>

    </div>
        

</div>
</template>

<script>
import highlight from './Highlight'

export default {
    data () {
        return {
            // textSearch: "",
            filterTags: [],
            filteredHighlights: [],
        }
    },
    mounted () {
        this.generateHighlights()
    },
    methods : {
        isSelected (tag) {

             if (this.filterTags.length == 0) {
                 return false
             } else {
                 return this.filterTags.includes(tag)
             }     

        },
        updateFilter: function (tag) {

             let i = this.filterTags.findIndex( filterTag => { 
                return filterTag == tag 
            })

            if ( i >= 0) {
                this.filterTags.splice(i, 1)
            } else {
                this.filterTags.push(tag)
            }

        },
        generateHighlights: function () {
            
        if (!this.highlights) {
            this.filteredHighlights = []
            return
        }
        
        let highlightList = [...this.highlights]
        highlightList.sort((a, b) => (a.TimeStart > b.TimeStart) ? 1 : -1)

           if (this.filterTags.length == 0 ) {
                this.filteredHighlights = highlightList
           } else {
                    const filter = this.filterTags == [] ? this.tags : this.filterTags
                    let array = []
                            if (filter.length > 0) {
                                filter.forEach(element => {
                                    highlightList.forEach(highlight => {
                                        if (highlight.Tags) {
                                            if (highlight.Tags.includes(element)) {
                                                array.push(highlight)
                                            }
                                        }
                                    })
                                })
                            }
                            this.filteredHighlights = [... new Set(array)]
                        }
        }
    },
    props: ["highlights"],
    components: {
        highlight
    },
    watch: {
        highlights : function () {
            this.generateHighlights()
        },
        filterTags : function () {
            this.generateHighlights()
        },
    },
    computed : {
        // highlights () {
        //     return this.$store.getters.HIGHLIGHTS
        // },
        tagList () {

            if (!this.highlights) {
                return []
            }
            
            let tagList = []

            this.highlights.forEach(highlight => {
                if (highlight.Tags) {
                    highlight.Tags.forEach(tag => {

                        if (!tagList.includes(tag)) {
                            tagList.push(tag)
                        }

                    })
                }
            })

            return tagList

        }
    }
}
</script>

<style scoped>

#highlight-box {
    margin-bottom: 10em;
}

.tag-filter {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
}

.tag-chip {
    background-color: #4a4a4a15;
    color: #4a4a4a80;
    border: 1px solid #4a4a4a30;
}

.tag-chip.active{
    color: #0BAAFF;
    border: 1px solid #0BAAFF;
    background-color: white;
    box-shadow: 3px 3px 10px #4a4a4a30 ;
}

.tag-icon {
    margin-right: -10px;
}

.list-enter-active, .list-leave-active {
  transition: all 0.3s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}

</style>
